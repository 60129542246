import React, { useEffect, useState } from "react";
import EpisodeTabNavigation from "../EpisodeTabNavigation";
import LineChart from "../../../components/charts/LineChart";
import { useLocation } from "react-router-dom";
import { getData } from "../../../utils/API";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import { convertDateFormat } from "../../../utils/convertDateFormat";
import { Box } from "@mui/material";

const EpisodeDashboard = () => {
  const location = useLocation();
  const { notificationState, handleErrorNotification, handleClose } = useNotificationHandling();
  const { episodeOfCare } = location.state;
  const episodeOfCareId = episodeOfCare["id"];

  const [loading, setLoading] = useState(true);
  const [labels, setLabels] = useState([]);
  const [dataSets, setDataSets] = useState([]);

  const table = "episode_questionnaire_scores";
  const query_params = {
    episode_of_care_id: episodeOfCareId,
  };

  const processData = (data) => {
    const groupedData = {};

    // Group data by questionnaire_acronym
    data.forEach(item => {
      if (!groupedData[item.questionnaire_acronym]) {
        groupedData[item.questionnaire_acronym] = {};
      }
      groupedData[item.questionnaire_acronym][item.score_date] = item.score;
    });

    // Extract unique dates and sort them
    const uniqueDates = [...new Set(data.map(item => item.score_date))].sort();

    const colors = [
      'rgba(75,192,192,1)',
      'rgba(153,102,255,1)',
      'rgba(255,99,132,1)',
      // Add more colors if needed
    ];

    const datasets = Object.keys(groupedData).map((acronym, index) => {
      const color = colors[index % colors.length];
      return {
        label: acronym,
        data: uniqueDates.map(date => groupedData[acronym][date] !== undefined ? groupedData[acronym][date] : null),
        fill: false,
        backgroundColor: color,
        borderColor: color,
        pointBackgroundColor: color,
        pointBorderColor: color,
        pointRadius: 6,
        pointHoverRadius: 8,
      };
    });

    const formattedLabels = uniqueDates.map(convertDateFormat);

    return { labels: formattedLabels, datasets };
  };

  useEffect(() => {
    setLoading(true);
    getData(table, query_params)
      .then((data) => {
        const processedData = processData(data);
        console.log(data, 'processedData');
        setLabels(processedData.labels);
        setDataSets(processedData.datasets);
      })
      .catch((error) => {
        handleErrorNotification(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [episodeOfCareId, handleErrorNotification]);

  return (
    <div>
      <EpisodeTabNavigation />
      <Box
        sx={{
          width: "70%",
          paddingLeft: "40px",
        }}
      >
        <LineChart labels={labels} dataSets={dataSets} />
      </Box>
    </div>
  );
};

export default EpisodeDashboard;
