import * as React from 'react';
import '../../../../index.css';
import { useEffect, useState, useContext } from 'react';
import AnalysisDataGrid from '../../../../components/datagrid/analysisDataGrid';
import UserContext from '../../../../contexts/UserContext';
import { getData } from '../../../../utils/API';
import ShowAlert from '../../../../utils/ShowAlert';
import { useNotificationHandling } from '../../../../utils/NotificationHandling';

// *************** CUSTOMIZE ************** START
export default function ProviderFollowUpDataGrid() {
  const { role, practiceId, practice_name } = useContext(UserContext);
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();

  const title = practice_name;
  let subtitle = `New Patient Follow Ups`;

  const table = 'analysis_provider_follow_ups_quarter';

  // *************** CUSTOMIZE ************** END

  const [loading, setLoading] = useState(true);
  const [rows, setRawRows] = useState([]);

  const [practitioners, setPractitioners] = useState([]);
  const [years, setYears] = useState([]);
  const [quarters, setQuarters] = useState([]);

  const setRows = (rows) => {
    if (!Array.isArray(rows)) {
      return;
    }
    setRawRows(rows.map((r, i) => ({ ...r, no: i + 1 })));
  };

  useEffect(() => {
    setLoading(true);
    getData(table, { practice_id: 1 })
      .then((data) => {
        data.forEach((item) => {
          item.id = `${item.year}_${item.quarter}_${
            item.practitioner_id
          }_${Math.random().toString(36).substr(2, 9)}`;
        });
        setRows(data);
        const practitionerNames = [
          ...new Set(data.map((item) => item.practitioner_name)),
        ];
        setPractitioners(practitionerNames);
        const yearNames = [...new Set(data.map((item) => item.year))];
        setYears(yearNames);
        const quarterNames = [...new Set(data.map((item) => item.quarter))];
        setYears(quarterNames);
      })
      .catch((error) => {
        handleErrorNotification(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleErrorNotification]);

  // This has to load after related data useEffect
  const columns = [
    { field: 'id', headerName: 'ID', filterable: false, flex: 0.5 },
    {
      field: 'practitioner_name',
      headerName: 'Practitioner',
      cellClassName: 'name-column--cell',
      filterable: true,
      type: 'singleSelect',
      valueOptions: practitioners,
      flex: 2,
    },
    {
      field: 'year',
      headerName: 'Year',
      headerAlign: 'center',
      align: 'center',
      filterable: true,
      type: 'singleSelect',
      valueOptions: years,
      flex: 1,
    },
    {
      field: 'quarter',
      headerName: 'Qtr',
      headerAlign: 'center',
      align: 'center',
      filterable: true,
      type: 'singleSelect',
      valueOptions: quarters,
      flex: 1,
    },
    {
      field: 'new_patients',
      headerName: 'New Patients',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'median',
      headerName: 'Median',
      headerAlign: 'center',
      align: 'center',
      flex: 0.75,
    },
    {
      field: 'days_14_percent',
      headerName: '14 Days',

      headerAlign: 'center',
      align: 'center',
      flex: 0.75,
      valueFormatter: (params) => {
        return `${params.value}%`;
      },
    },
    {
      field: 'days_30_percent',
      headerName: '30 Days',
      headerAlign: 'center',
      align: 'center',
      flex: 0.75,
      valueFormatter: (params) => {
        return `${params.value}%`;
      },
    },
    {
      field: 'days_45_percent',
      headerName: '45 Days',
      headerAlign: 'center',
      align: 'center',
      flex: 0.75,
      valueFormatter: (params) => {
        return `${params.value}%`;
      },
    },
    {
      field: 'days_60_percent',
      headerName: '60 Days',
      headerAlign: 'center',
      align: 'center',
      flex: 0.75,
      valueFormatter: (params) => {
        return `${params.value}%`;
      },
    },
    {
      field: 'days_60p_percent',
      headerName: '60+ Days',
      headerAlign: 'center',
      align: 'center',
      flex: 0.75,
      valueFormatter: (params) => {
        return `${params.value}%`;
      },
    },
    {
      field: 'no_follow_up_percent',
      headerName: 'No Follow Up',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      valueFormatter: (params) => {
        return `${params.value}%`;
      },
    },
  ];

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <div>
      <AnalysisDataGrid
        title={title}
        subtitle={subtitle}
        columns={columns}
        rows={rows}
        loading={loading}
      />
    </div>
  );
}
