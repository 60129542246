import axios from 'axios';
import { config } from '../config/Config';
import { getToken } from './AuthService';
import { HandleTokenError } from './TokenError';
import logger from './logger';

async function makeRequest(method, options = {}) {
  const url = `${config.baseUrl}`;

  //console.log('makeRequest method:', method);
  //console.log('makeRequest options:', options);

  const token = await getToken();
  if (token instanceof Error) {
    return <HandleTokenError error={token} />;
  }

  const defaultHeaders = {
    Authorization: `Bearer ${token}`,
  };

  const response = await axios({
    method,
    url,
    headers: { ...defaultHeaders, ...options.headers },
    data: options.body,
    params: options.query_params,
  });

  //logger.info(`response from ${url}:`, response);
  //console.log('response:', response);
  return response;
}

export async function getOne(table, query_params) {
  const params = { ...query_params, table_name: table, operation: 'Read' };

  try {
    const response = await makeRequest('get', { query_params });

    if (!response.data.data.length) {
      const customError = new Error();
      customError.name = 'Data not found';
      customError.message =
        'No data was found that match your search parameters';
      throw customError;
    }

    return response.data.data[0];
  } catch (error) {
    throw error;
  }
}

export async function getDrchronoData(table, body) {
  try {
    const response = await makeRequest('post', table, { body });

    if (response.data.length === 0) {
      return [];
    }

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getData(table, params = {}) {
  const query_params = { ...params, table: table };
  //console.log('query_params:', query_params);

  try {
    const response = await makeRequest('get', { query_params });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postData(table, data) {
  const body = {};
  body['table'] = table;
  body['data'] = data;
  //console.log('body:', body);

  try {
    const response = await makeRequest('post', { body });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function putData(table, data) {
  const body = {};
  body['table'] = table;
  body['data'] = data;
  //console.log('body:', body);

  try {
    const response = await makeRequest('put', { body });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteData(table, body) {
  // Add the table name and operation to the body
  const data = { ...body, table_name: table, operation: 'Delete' };

  try {
    const response = await makeRequest('delete', table, { body });
    return response;
  } catch (error) {
    throw error;
  }
}
