import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Grid,
  Box,
  Collapse,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { ExpandMoreRounded, ExpandLessRounded } from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import logger from "../../../utils/logger";
import { getData, postData, putData } from "../../../utils/API";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import DataEntry from "../../../components/datagrid/dataEntry";
import {
  typeOfDate,
  validateDateDefined,
  validateRequiredAttributes,
  validateUniqueProperties,
} from "../../../utils/ValidationUtils";
import dayjs from "dayjs";

const table = "episode_tms_treatment_sessions";

const TMSTreatmentPlanCard = ({
  tms_device_manufacturer,
  tms_device_model,
  tms_coil_model_number,
  session_start,
  session_end,
  motor_threshold_calibrated,
  motor_threshold_percent,
  status,
  tms_protocol_name,
  onEdit,
  onDelete,
  changedValue,
  has_session,
  session_count,
  showSessions,
  onClick,
  treatmentPlan,
  practiceId,
  officeId,
  patientId,
  episodeOfCareId,
  practitionerId,
  treatmentSessions,
  episodeStartDate,
  episodeEndDate,
}) => {
  const highlightStyle = { backgroundColor: "yellow" };
  const { handleErrorNotification } = useNotificationHandling();
  const [sessions, setSessions] = useState(treatmentSessions || []);
  const [practitionerObjects, setPractitionerObjects] = useState([]);
  const motorThresholdCalibratedValue =
    treatmentPlan.motor_threshold_calibrated;
  const motorThresholdPercent = treatmentPlan.motor_threshold_percent;
  const motor_threshold_goal = Math.floor(
    motorThresholdCalibratedValue * (motorThresholdPercent / 100)
  );
  const columns = [
    { field: "session_number", headerName: "Session number", flex: 0.5 },
    {
      field: "practitioner_name",
      headerName: "Practitioner Name",
      editable: true,
      type: "singleSelect",
      valueOptions: practitionerObjects.map((obj) => obj.full_name),
      width: 200,
    },
    {
      field: "session_date",
      headerName: "Session Date",
      headerAlign: "left",
      align: "left",
      editable: true,
      type: "date",
      flex: 1,
      valueGetter: (params) => {
        const date = params.row.session_date;
        const dateObject = typeOfDate(date, "object");
        return dateObject;
      },
    },
    {
      field: "motor_threshold_initial",
      headerName: "MT Initial",
      headerAlign: "left",
      align: "left",
      type: "number",
      editable: true,
      flex: 1,
    },
    {
      field: "motor_threshold_final",
      headerName: "MT Final",
      headerAlign: "left",
      align: "left",
      editable: true,
      type: "number",
      flex: 1,
    },
    {
      field: "total_pulses",
      headerName: "Total Pulses",
      headerAlign: "left",
      align: "left",
      editable: false,
      type: "number",
      flex: 1,
    },
  ];

  const handleDeleteTreatmentSession = async (id) => {
    logger.info(`handleDeleteTreatmentSession ${id}`);
    try {
      await putData(table, { id: id, deleted: true });
    } catch (error) {
      handleErrorNotification(error);
    }

    setSessions((prevSessions) =>
      prevSessions.filter((session) => session.id !== id)
    );
  };

  const deleteRow = (id) => {
    handleDeleteTreatmentSession(id);
  };

  const getNextWeekday = (date) => {
    let nextDate = dayjs(date).add(7, "day");
    if (nextDate.day() === 0) {
      nextDate = dayjs(date).add(1, "day");
    } else if (nextDate.day() === 6) {
      nextDate = dayjs(date).add(2, "day");
    }
    return nextDate.format("YYYY-MM-DD");
  };

  const createRowData = (rows) => {
    const newId = Math.floor(100000 + Math.random() * 900000);
    const maxSession =
      sessions && sessions.length > 0
        ? sessions.reduce((max, item) =>
            dayjs(item.session_date).valueOf() >
            dayjs(max.session_date).valueOf()
              ? item
              : max
          )
        : null;
    const nextSessionDate = maxSession
      ? getNextWeekday(maxSession.session_date)
      : episodeStartDate;

    return {
      id: newId,
      practitioner_name:
        practitionerObjects.find((obj) => obj.id === practitionerId)
          ?.full_name || "",
      session_date: nextSessionDate,
      motor_threshold_initial: motor_threshold_goal,
      motor_threshold_final: motor_threshold_goal,
      total_pulses: treatmentPlan.total_pulses,
    };
  };

  const validateRow = async (newRow) => {
    const requiredAttributes = [
      "session_date",
      "motor_threshold_initial",
      "motor_threshold_final",
    ];
    const attributeNames = ["Session Date", "MT Initial", "MT Final"];
    const max = treatmentPlan.motor_threshold_calibrated * 2;
    const min = 1;

    try {
      validateRequiredAttributes(requiredAttributes, attributeNames, newRow);
      validateDateDefined(newRow["session_date"], "Session Date");

      if (
        newRow["motor_threshold_initial"] > max ||
        newRow["motor_threshold_final"] > max
      ) {
        const customError = new Error();
        customError.name = "Validation Error";
        customError.message = `Motor Threshold must be at most ${max}`;
        throw customError;
      }
      if (
        newRow["motor_threshold_initial"] < min ||
        newRow["motor_threshold_final"] < min
      ) {
        const customError = new Error();
        customError.name = "Validation Error";
        customError.message = `Motor Threshold must be at least ${min}`;
        throw customError;
      }

      if (new Date(newRow["session_date"]) <= new Date(episodeStartDate)) {
        const customError = new Error();
        customError.name = "Validation Error";
        customError.message = `Session Date must be on or after episode start date ${episodeStartDate}`;
        throw customError;
      }
      if (new Date(newRow["session_date"]) >= new Date(episodeEndDate)) {
        const customError = new Error();
        customError.name = "Validation Error";
        customError.message = `Session Date must be on or before episode end date ${episodeEndDate}`;
        throw customError;
      }
      return newRow;
    } catch (error) {
      throw error;
    }
  };

  function getObjectID(newRow, keyToCompare, objectsArray, matchingKey) {
    const object = objectsArray.find(
      (obj) => obj[matchingKey] === newRow[keyToCompare]
    );
    if (object) {
      return object.id;
    } else {
      const customError = new Error();
      customError.name = "Data Validation Error";
      customError.message = `${keyToCompare} not found.`;
      throw customError;
    }
  }

  const validateSaveValues = async (oldRows, newRow) => {
    const requiredAttributes = [
      "episode_of_care_id",
      "practice_tms_treatment_plan_id",
      "session_date",
    ];

    try {
      const isValidate = validateUniqueProperties(
        oldRows,
        newRow,
        requiredAttributes
      );
    } catch (error) {
      handleErrorNotification(error);
      return;
    }
    return false;
  };

  async function saveRow(id, row, oldRow, oldRows) {
    try {
      const motorThresholdFinal = row.motor_threshold_final;
      const motorThresholdPercentFinal = motorThresholdFinal
        ? Math.ceil(
            (motorThresholdFinal / treatmentPlan.motor_threshold_calibrated) *
              100
          )
        : null;
      const practitionerId = getObjectID(
        row,
        "practitioner_name",
        practitionerObjects,
        "full_name"
      );

      const rowToSave = {
        ...row,
        practice_id: practiceId,
        practitioner_id: practitionerId,
        office_id: officeId,
        patient_id: patientId,
        episode_of_care_id: episodeOfCareId,
        episode_tms_treatment_plan_id: treatmentPlan.id,
        motor_threshold_calibrated: treatmentPlan.motor_threshold_calibrated,
        total_pulses: treatmentPlan.total_pulses,
        status: "Active",
        deleted: false,
        tms_device_manufacturer: treatmentPlan.tms_device_manufacturer,
        tms_device_model_number: treatmentPlan.tms_device_model_number,
        tms_coil_model_number: treatmentPlan.tms_coil_model_number,
        tms_protocol_name: treatmentPlan.tms_protocol_name,
        motor_threshold_percent_final: motorThresholdPercentFinal,
      };

      const isValidate = await validateSaveValues(treatmentSessions, rowToSave);
      if (isValidate) {
        return;
      }

      if (row.isNew) {
        delete rowToSave.id;
        const res = await postData(table, rowToSave);
        rowToSave.id = res.data.id;
        rowToSave.practitioner_name = practitionerObjects.find(
          (obj) => obj.id === rowToSave.practitioner_id
        ).full_name;
        setSessions(oldRows.map((r) => (r.id === id ? { ...rowToSave } : r)));
      } else {
        await putData(table, rowToSave);
        setSessions(oldRows.map((r) => (r.id === id ? { ...row } : r)));
      }
      return rowToSave;
    } catch (error) {
      setSessions(oldRows);
      handleErrorNotification(error);
    }
  }

  const getPractitioners = async () => {
    try {
      const data = await getData("practice_practitioners", {
        deleted: false,
        status: "Active",
      });
      setPractitionerObjects(data);
    } catch (error) {
      handleErrorNotification(error);
    }
  };

  useEffect(() => {
    getPractitioners();
  }, []);

  return (
    <Box>
      <Card
        sx={{
          width: "100%",
          mb: 1,
          border: status === "Active" ? "2px solid green" : "none",
        }}
        onClick={onClick}
      >
        <CardContent sx={{ padding: "10px 4px" }}>
          <Grid container spacing={1}>
            {/* Row 1 */}
            <Grid item xs={1.5}>
              <Typography variant="subtitle1">Device</Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={changedValue === "device" ? highlightStyle : {}}
              >
                {tms_device_manufacturer}
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={changedValue === "device_model" ? highlightStyle : {}}
              >
                {tms_device_model}
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={changedValue === "coil_model" ? highlightStyle : {}}
              >
                {tms_coil_model_number} coil
              </Typography>
            </Grid>
            <Grid
              item
              xs={1.5}
              container
              direction="column"
              alignItems="center"
            >
              <Typography variant="subtitle1">Protocol</Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={changedValue === "protocol" ? highlightStyle : {}}
              >
                {tms_protocol_name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1.5}
              container
              direction="column"
              alignItems="center"
            >
              <Typography variant="subtitle1" align="center">
                MT Percent
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                align="center"
                sx={
                  changedValue === "motor_threshold_percent"
                    ? highlightStyle
                    : {}
                }
              >
                {motor_threshold_percent} %
              </Typography>
            </Grid>
            <Grid
              item
              xs={1.5}
              container
              direction="column"
              alignItems="center"
            >
              <Typography variant="subtitle1">MT Calibrated</Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={
                  changedValue === "motor_threshold_calibrated"
                    ? highlightStyle
                    : {}
                }
              >
                {motor_threshold_calibrated}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1.5}
              container
              direction="column"
              alignItems="center"
            >
              <Typography variant="subtitle1">Session Start</Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={changedValue === "session_start" ? highlightStyle : {}}
              >
                {session_start}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1.5}
              container
              direction="column"
              alignItems="center"
            >
              <Typography variant="subtitle1">Session End</Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={changedValue === "session_end" ? highlightStyle : {}}
              >
                {session_end}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1.5}
              container
              direction="column"
              alignItems="center"
            >
              {status === "Active" ? (
                <>
                  <CheckCircleOutlineIcon color="success" fontSize="large" />
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ mt: 1, textAlign: "center" }}
                  >
                    ACTIVE
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ textAlign: "center" }}
                  >
                    {has_session &&
                      session_count +
                        " Treatment Session" +
                        (session_count > 1 ? "s" : "")}
                  </Typography>
                </>
              ) : status === "Inactive" ? (
                <>
                  <HighlightOffIcon color="error" fontSize="large" />
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ mt: 1 }}
                  >
                    INACTIVE
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ textAlign: "center" }}
                  >
                    {has_session &&
                      session_count +
                        " Treatment Session" +
                        (session_count > 1 ? "s" : "")}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="subtitle1">Status</Typography>
                  <Typography variant="body1" color="text.secondary">
                    {status}
                  </Typography>
                </>
              )}
              {/* {has_session && (
                <IconButton
                  size="large"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <MedicalServicesOutlinedIcon sx={{
                    fontSize: '30px'
                  }} />
                  <Typography>{session_count}</Typography>
                </IconButton>
              )} */}
            </Grid>
            <Grid
              item
              xs={1.5}
              container
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <IconButton onClick={onClick} aria-label="collapse" size="large">
                {showSessions ? <ExpandLessRounded /> : <ExpandMoreRounded />}
              </IconButton>
              <IconButton onClick={onEdit} aria-label="edit" size="large">
                <EditIcon />
              </IconButton>
              <IconButton onClick={onDelete} aria-label="delete" size="large">
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Collapse in={showSessions}>
        {/* Session card */}
        <Card
          sx={{
            width: "100%",
            mb: 1,
          }}
        >
          <DataEntry
            containerStyle={{
              height: "auto",
              m: 0,
            }}
            columns={columns}
            rows={sessions.map((session, i) => ({ ...session, no: i + 1 }))}
            onValidateRow={validateRow}
            onSaveRow={saveRow}
            onDeleteRow={deleteRow}
            createRowData={createRowData}
            loading={practitionerObjects.length <= 0}
          />
        </Card>
      </Collapse>
    </Box>
  );
};

export default TMSTreatmentPlanCard;
