import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import TMSTreatmentPlanCard from "./TMSTreatmentPlanCard";
import TMSTreatmentPlanForm from "./TMSTreatmentPlanForm";
import logger from "../../../utils/logger";
import { getData, postData, putData } from "../../../utils/API";
import ShowAlert from "../../../utils/ShowAlert";
import { useNotificationHandling } from "../../../utils/NotificationHandling";
import { twoAttributeSort } from "../../../utils/sort";
import EpisodeTabNavigation from "../EpisodeTabNavigation";
import { validateUniqueProperties } from "../../../utils/ValidationUtils";

const EpisodeTMSTreatmentPlans = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();

  const location = useLocation();
  const { episodeOfCare } = location.state;
  const patientName = `${episodeOfCare["first_name"]} ${episodeOfCare["last_name"]}`;
  const practiceId = episodeOfCare["practice_id"];
  const officeId = episodeOfCare["office_id"];
  const patientId = episodeOfCare["patient_id"];
  const episodeOfCareId = episodeOfCare["id"];
  const practitionerId = episodeOfCare["practitioner_id"];
  const episodeStartDate = episodeOfCare["start_date"];
  const episodeEndDate = episodeOfCare["end_date"];
  const table = "episode_tms_treatment_plans";
  const sort_1 = "status";
  const sort_2 = "session_start";

  const [treatmentPlan, setTreatmentPlan] = useState([]);
  const [practiceTreatmentPlans, setPracticeTreatmentPlans] = useState([]);
  const [episodeTreatmentSessions, setEpisodeTreatmentSessions] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [treatmentPlanData, setTreatmentPlanData] = useState();
  const [episodeTmsTreatMentPlanId, setEpisodeTmsTreatMentPlanId] = useState();
  const [openAddTreatmentPlanDialog, setOpenAddTreatmentPlanDialog] =
    useState(false);
  const [showSessionCard, setShowSessionCard] = useState([]);

  const query_params = {
    practice_id: practiceId,
    episode_of_care_id: episodeOfCareId,
    deleted: false,
  };

  const getPlanData = () => {
    getData(table, query_params)
      .then((data) => {
        const sortedItems = twoAttributeSort(
          data,
          sort_1,
          sort_2,
          false,
          false
        );

        const updatedDataPromises = sortedItems.map((item) => {
          return treatmentSessionExists(item.id).then((sessionExists) => {
            item.has_session = sessionExists > 0;
            item.session_count = sessionExists;
            return item;
          });
        });

        Promise.all(updatedDataPromises)
          .then((updatedData) => {
            setTreatmentPlan(updatedData);
          })
          .catch((error) => {
            handleErrorNotification(error);
          });
      })
      .catch((error) => {
        handleErrorNotification(error);
      });
  };

  const validateRow = async (oldRows, newRow) => {
    const requiredAttributes = [
      "episode_of_care_id",
      "practice_tms_treatment_plan_id",
      "session_end",
    ];

    try {
      validateUniqueProperties(oldRows, newRow, requiredAttributes);
    } catch (error) {
      handleErrorNotification(error);
      return true;
    }
    return false;
  };

  const saveTreatmentPlan = async (values) => {
    const isValidate = await validateRow(treatmentPlan, values);
    if (isValidate) {
      return;
    }

    if (isUpdate) {
      putData(table, values)
        .then((data) => {
          getPlanData();
        })
        .catch((error) => {
          handleErrorNotification(error);
        });
    } else {
      postData(table, values)
        .then((data) => {
          getPlanData();
        })
        .catch((error) => {
          handleErrorNotification(error);
        });
    }
    setIsUpdate(false);
  };

  const handleAddOrUpdateTreatmentPlan = (values) => {
    if (isUpdate) {
      values.id = episodeTmsTreatMentPlanId;
    }
    setOpenAddTreatmentPlanDialog(false);
    saveTreatmentPlan(values);
  };

  const openEditTreatmentPlanModal = async (values) => {
    logger.info(`handleEditTreatmentPlan ${values}`);
    setEpisodeTmsTreatMentPlanId(values.id);
    const treatmentsExists = await treatmentSessionExists(values.id);

    if (treatmentsExists > 0) {
      setIsUpdate(false);
      const customError = new Error();
      customError.name = "Edit Error";
      customError.message = `This Treatment Plan has Treatment Sessions and cannot be edited.\nDelete the Treatment Sessions or Add a new Treatment Plan and move the Treatment Sessions to the new Treatment Plan and then do the edit.`;
      handleErrorNotification(customError);
      setOpenAddTreatmentPlanDialog(true);
      setTreatmentPlanData({});
      return;
    } else {
      setIsUpdate(true);
      setTreatmentPlanData(values);
      setOpenAddTreatmentPlanDialog(true);
    }
  };

  async function treatmentSessionExists(id) {
    try {
      const result = await getData("episode_tms_treatment_sessions", {
        episode_tms_treatment_plan_id: id,
        deleted: false,
        episode_of_care_id: episodeOfCareId,
      });

      if (result.length === 0) {
        return false;
      } else {
        return result.length;
      }
    } catch (error) {
      return false;
    }
  }

  const handleDeleteTreatmentPlan = async (id) => {
    logger.info(`handleDeleteTreatmentPlan ${id}`);

    const treatmentsExists = await treatmentSessionExists(id);
    if (treatmentsExists) {
      const customError = new Error();
      customError.name = "Delete Error";
      customError.message = `This Treatment Plan has Treatment Sessions and cannot be deleted.\nDelete the Treatment Sessions or Add a new Treatment Plan and move the Treatment Sessions to the new Treatment Plan and then do the delete.`;
      handleErrorNotification(customError);
      return;
    }
    try {
      await putData(table, { id: id, deleted: true });
    } catch (error) {
      handleErrorNotification(error);
    }

    setTreatmentPlan(() =>
      treatmentPlan.filter((treatmentPlan) => treatmentPlan.id !== id)
    );
  };

  const handleCardClick = (index) => {
    if (showSessionCard.includes(index)) {
      setShowSessionCard([]);
    } else {
      setShowSessionCard([index]);
    }
  };

  const getTreatmentSessions = async () => {
    const table = "episode_tms_treatment_sessions";

    try {
      const data = await getData(table, query_params);
      setEpisodeTreatmentSessions(data || []);
    } catch (error) {
      handleErrorNotification(error);
    }
  };

  useEffect(() => {
    const queryParams = {
      practice_id: practiceId,
      office_id: officeId,
      deleted: false,
    };
    getData("practice_tms_treatment_plans", queryParams)
      .then((data) => {
        setPracticeTreatmentPlans(data);
      })
      .catch((error) => {
        handleErrorNotification(error);
      });
  }, [practiceId, officeId]);

  useEffect(() => {
    getPlanData();
    getTreatmentSessions();
  }, []);

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <Box>
      {/* HEADER */}
      <EpisodeTabNavigation />
      <Box mt={3} display="flex" flexDirection="column" alignItems="flex-start">
        <Header title={patientName} />
      </Box>

      <Box display="flex" justifyContent="space-between" width="100%">
        {/* Diagnosis cards on the left */}
        <Box
          width="100%"
          mr={2}
          sx={{ padding: 2, backgroundColor: colors.blueAccent[600] }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h5"
              fontWeight="800"
              align="left"
              component="div"
              gutterBottom
              sx={{ color: colors.grey[100] }}
            >
              TREATMENT PLANS
            </Typography>
            <IconButton
              color="primary"
              onClick={() => {
                setOpenAddTreatmentPlanDialog(true);
                setTreatmentPlanData({});
                setIsUpdate(false);
              }}
            >
              <AddCircleOutlineIcon
                sx={{
                  color: theme.palette.mode === "dark" ? "#fff" : "#000",
                }}
              />
            </IconButton>
          </Box>

          {treatmentPlan.map((treatment_plan, index) => (
            <TMSTreatmentPlanCard
              treatmentPlan={treatment_plan}
              showSessions={showSessionCard.includes(index)}
              key={treatment_plan.id}
              tms_device_manufacturer={treatment_plan.tms_device_manufacturer}
              tms_device_model={treatment_plan.tms_device_model_number}
              tms_coil_model_number={treatment_plan.tms_coil_model_number}
              tms_protocol_name={treatment_plan.tms_protocol_name}
              motor_threshold_percent={treatment_plan.motor_threshold_percent}
              motor_threshold_calibrated={
                treatment_plan.motor_threshold_calibrated
              }
              total_pulses={treatment_plan.total_pulses}
              session_start={treatment_plan.session_start}
              session_end={treatment_plan.session_end}
              status={treatment_plan.status}
              has_session={treatment_plan.has_session}
              session_count={treatment_plan.session_count}
              treatmentSessions={episodeTreatmentSessions.filter(
                (session) =>
                  session.episode_tms_treatment_plan_id === treatment_plan.id
              )}
              practiceId={practiceId}
              practitionerId={practitionerId}
              officeId={officeId}
              patientId={patientId}
              episodeOfCareId={episodeOfCareId}
              episodeStartDate={episodeStartDate}
              episodeEndDate={episodeEndDate}
              onEdit={() => openEditTreatmentPlanModal(treatment_plan)}
              onDelete={() => handleDeleteTreatmentPlan(treatment_plan.id)}
              onClick={() => handleCardClick(index)}
            />
          ))}
        </Box>
      </Box>
      <Dialog
        open={openAddTreatmentPlanDialog}
        onClose={() => setOpenAddTreatmentPlanDialog(false)}
      >
        <DialogTitle>
          {isUpdate ? "Edit Treatment Plan" : "Add Treatment Plan"}
        </DialogTitle>
        <DialogContent>
          <TMSTreatmentPlanForm
            onSubmit={(values) => handleAddOrUpdateTreatmentPlan(values)}
            onCancel={() => setOpenAddTreatmentPlanDialog(false)}
            practiceId={practiceId}
            officeId={officeId}
            episodeOfCareId={episodeOfCareId}
            patientId={patientId}
            practiceTreatmentPlans={practiceTreatmentPlans}
            treatmentPlanData={treatmentPlanData}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EpisodeTMSTreatmentPlans;
