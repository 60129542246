import React, { createContext, useState, useMemo } from 'react';
import { Auth } from 'aws-amplify';
import ShowAlert from '../utils/ShowAlert';
import { useNotificationHandling } from '../utils/NotificationHandling';
import logger from '../utils/logger';
import { getData } from '../utils/API';

const staticDefaultUserValue = {
  role: '',
  practiceId: '',
  email: '',
  firstName: '',
  lastName: '',
  roleId: '',
};

const UserContext = createContext(staticDefaultUserValue);

function UserProvider({ children }) {
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();

  const [user, setUser] = useState(staticDefaultUserValue);
  const [defaultUserData, setDefaultUserData] = useState(
    staticDefaultUserValue
  );

  const userInfo = useMemo(() => {
    const setUserData = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const emailAttribute = currentUser.attributes.email;

        // Retrieve custom attributes from the user attributes
        const customAttributes = currentUser.attributes; // This contains all user attributes

        // Example: Retrieve a specific custom attribute
        const practice_id = customAttributes['custom:practice_id'];
        const role_id = customAttributes['custom:role_id'];

        const roles = await getData('roles', {});
        const role = roles.filter((role, index) => role.id == role_id);
        const firstName = currentUser.attributes.given_name;
        const lastName = currentUser.attributes.family_name;

        const practice = await getData('practices', {});

        logger.debug(
          `User ${lastName} initialized with ${practice_id} in role ${role}`
        );

        const userDataValues = {
          role: role[0].name,
          practice_name: practice[0].name,
          practiceId: practice_id,
          email: emailAttribute,
          firstName: currentUser.attributes.given_name,
          lastName: currentUser.attributes.family_name,
          roleId: role_id,
        };

        // Check if user data is different from current state
        if (JSON.stringify(userDataValues) !== JSON.stringify(user)) {
          setUser(userDataValues);
          setDefaultUserData(userDataValues);
        }

        return user;
      } catch (error) {
        const customError = new Error();
        customError.name = 'Authentication Error';
        customError.message = 'Login to OutcomesAI to continue';
        handleErrorNotification(customError);
      }
    };

    return {
      ...defaultUserData,
      setUserData,
    };
  }, [defaultUserData, user, setUser, handleErrorNotification]);

  return (
    <UserContext.Provider value={userInfo}>
      {notificationState.showNotification && (
        <ShowAlert
          severity={notificationState.severity}
          title={notificationState.title}
          message={notificationState.message}
          description={notificationState.description}
          onClose={handleClose}
        />
      )}
      {children}
    </UserContext.Provider>
  );
}

export { UserProvider };
export default UserContext;
