// AWS config for different environments

const configDev = {
  baseUrl: 'https://bczsb0531k.execute-api.us-west-2.amazonaws.com/dev/data',
  stage: 'dev',
};

const awsConfigDev = {
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_xAQU2nFIC',
    userPoolWebClientId: '6gutn6ic6cq6pvtdosfk1191d1',
    oauth: {
      domain:
        'https://outcomesai-dev-user-pool-domain.auth.us-west-2.amazoncognito.com',
      scope: ['email', 'openid', 'phone'],
      redirectSignIn: 'http://localhost:3000',
      redirectSignOut: 'http://localhost:3000',
      responseType: 'code',
    },
  },
};

const configProd = {
  baseUrl: 'https://t9mo8clwc4.execute-api.us-west-1.amazonaws.com/prod/data',
  stage: 'prod',
};

const awsConfigProd = {
  Auth: {
    region: 'us-west-1',
    userPoolId: 'us-west-1_FK0ejjIEp',
    userPoolWebClientId: '23nbheg3gr394cve9atq310u6r',
    oauth: {
      domain:
        'https://outcomesai-prod-user-pool-domain.auth.us-west-1.amazoncognito.com',
      scope: ['email', 'openid', 'phone'],
      redirectSignIn: 'http://app.outcomesai.com',
      redirectSignOut: 'http://app.outcomesai.com',
      responseType: 'code',
    },
  },
};

export const config = configProd;
export const awsConfig = awsConfigProd;
