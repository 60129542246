// RequireAuth.js

import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

export function RequireAuth({ children }) {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  const { user } = useAuthenticator((context) => [context.user]);

  if (!user) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }
  return children;
}
