import * as Yup from 'yup';

const validationSchema = (
  treatmentSessions,
  episodeStartDate,
  episodeEndDate,
  isUpdate,
  max = 150
) =>
  Yup.object().shape({
    episode_tms_treatment_plan_id: Yup.string().required(
      'Treatment Plan is required'
    ),
    session_number: Yup.number()
      .min(1)
      .required('Session Number is required'),
      // .test('is-unique', function (value) {
      //   const treatmentPlanId = this.parent.episode_tms_treatment_plan_id;
      //   const duplicateSession = treatmentSessions.find(
      //     (session) =>
      //       session.session_number === value &&
      //       session.episode_tms_treatment_plan_id === treatmentPlanId
      //   );

      //   if (duplicateSession) {
      //     return this.createError({
      //       message: `Session ${value} already exists for ${duplicateSession.tms_protocol_name}`,
      //     });
      //   }
      //   return true;
      // })
      // .test('is-sequential', function (value) {
      //   const maxExistingNumber = treatmentSessions.reduce(
      //     (max, session) => Math.max(max, session.session_number),
      //     0
      //   );
      //   return isUpdate || value === maxExistingNumber + 1
      //     ? true
      //     : this.createError({
      //         message: `Session Number must be sequential. The last session number was ${maxExistingNumber}.`,
      //       });
      // }),
    motor_threshold_initial: Yup.number()
      .min(1, 'Motor Threshold must be at least 1')
      .max(max, `Motor Threshold must be at most ${max}`)
      .required('Initial Motor Threshold is required'),
    motor_threshold_final: Yup.number()
      .min(1, 'Motor Threshold must be at least 1')
      .max(max, `Motor Threshold must be at most ${max}`)
      .required('Final Motor Threshold is required'),
    session_date: Yup.date()
      .required('Session Date is required')
      // .test('is-after-previous-dates', function (value) {
      //   const sessionNumber = this.parent.session_number;
      //   const previousSessions = treatmentSessions.filter(
      //     (session) => session.session_number < sessionNumber
      //   );
      //   const lastSession = previousSessions.reduce(
      //     (latest, session) =>
      //       new Date(session.session_date) > new Date(latest.session_date)
      //         ? session
      //         : latest,
      //     previousSessions[0] || {}
      //   );

      //   return previousSessions.every(
      //     (session) => new Date(value) > new Date(session.session_date)
      //   )
      //     ? true
      //     : this.createError({
      //         message: `Session Date must be after all previous sessions. The last session was ${lastSession.session_number} on date ${lastSession.session_date}.`,
      //       });
      // })
      .test(
        'is-after-episode-start',
        `Session Date must be on or after episode start date ${episodeStartDate}`,
        function (value) {
          return new Date(value) >= new Date(episodeStartDate);
        }
      )
      .test(
        'is-before-episode-end',
        `Session Date must be on or before episode end date ${episodeEndDate}`,
        function (value) {
          if (!episodeEndDate || episodeEndDate.length === 0) {
            return true;
          }
          return new Date(value) <= new Date(episodeEndDate);
        }
      ),
    session_time: Yup.string(),
  });

export default validationSchema;
