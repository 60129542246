// src/App.js
import React, { useState, useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import logger from './utils/logger';
import Topbar from './components/Topbar';
import Sidebar from './components/Sidebar';
import Login from './pages/Login';
import AppRoutes from './AppRoutes';
import { ColorModeContext, useMode } from './theme';
import { Authenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';

// test commit

function App() {
  //logger.info('Info: Application loaded');

  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);

  useEffect(() => {
    async function checkAuthentication() {
      try {
        await Auth.currentSession();
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
      }
    }

    checkAuthentication();
  }, [loginSuccess]);

  function handleSuccessfulLogin() {
    setLoginSuccess(true); // Toggle the state
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Authenticator.Provider>
          <CssBaseline />
          <div className='app'>
            {isAuthenticated && <Sidebar isSidebar={isSidebar} />}
            <main className='content'>
              <Topbar setIsSidebar={setIsSidebar} />
              {isAuthenticated ? (
                <AppRoutes
                  isAuthenticated={isAuthenticated}
                  handleSuccessfulLogin={handleSuccessfulLogin}
                />
              ) : (
                <Login onSuccessfulLogin={handleSuccessfulLogin} />
              )}
            </main>
          </div>
        </Authenticator.Provider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
