import * as React from 'react';
import { useState } from 'react';
//import { Switch } from '@mui/material';
import { useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import {
  GridRowModes,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';
import GridExcelExportMenuItem from '../GridExcelExportMenuItem';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { color } from '@mui/system';

function DefaultToolbar(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {
    rows,
    setRows,
    setRowModesModel,
    columns,
    createRowData,
    hideAddIcon,
    onClick,
  } = props;

  //const [showInactive, setShowInactive] = useState(true);

  const handleClick = () => {
    if (props.type && props.type === 'form_entry') {
      onClick();
      return;
    }
    const newData = onClick(rows);
    newData.isNew = true;
    if (!newData.hasOwnProperty('id')) {
      newData.newId = Math.max(...rows.map((r) => r.id * 1)) + 1;
    }
    setRows((oldRows) => [...oldRows, newData]);
    setRowModesModel((oldModel) => {
      const firstEditable = columns.find((c) => c.editable && !c.hide);
      return {
        ...oldModel,
        [newData.id]: {
          mode: GridRowModes.Edit,
          fieldToFocus: firstEditable.field,
        },
      };
    });
  };

  /*
  // Function to handle switch toggle
  const handleSwitchChange = () => {
    // Your custom logic here for the Switch's onChange event
    // For example, you can perform filtering or any other actions.

    // For demonstration purposes, let's just update the switch state.
    setShowInactive(!showInactive);
  };

  Add below button
  <div style={{ marginLeft: 'auto' }}>
        <Switch
          checked={showInactive}
          onChange={handleSwitchChange}
          color='secondary'
        />
        Show Inactive
      </div>
  */

  if (hideAddIcon === false) {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExportContainer>
          <GridExcelExportMenuItem columns={columns} />
          <GridCsvExportMenuItem />
        </GridToolbarExportContainer>
        <GridToolbarQuickFilter />
        <Button
          color='primary'
          startIcon={<AddIcon style={{ color: colors.iconColor[100] }} />}
          onClick={handleClick}
        >
          Add record
        </Button>
      </GridToolbarContainer>
    );
  } else {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExportContainer>
          <GridExcelExportMenuItem columns={columns} />
          <GridCsvExportMenuItem />
        </GridToolbarExportContainer>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }
}

// color={colors.grey[100]}

DefaultToolbar.defaultProps = {
  createRowData: (rows) => {
    const newId = Math.max(...rows.map((r) => r.id * 1)) + 1;
    return { id: newId };
  },
  hideAddIcon: false,
};

export default DefaultToolbar;
