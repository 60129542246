// testScores/TestScoreDataGrid.jsx
// Based on TMS_Protocols

import * as React from 'react';
import '../../../index.css';
import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import DataEntry from '../../../components/datagrid/dataEntry';
import ViewOnly from '../../../components/datagrid/viewOnly';
import UserContext from '../../../contexts/UserContext';
import { getData, postData, putData } from '../../../utils/API';
import {
  validateRequiredAttributes,
  validateDateDefined,
  validateDate2GreaterThanOrEqualDate1,
  validateNotFutureDate,
  typeOfDate,
  validateUniqueProperties,
} from '../../../utils/ValidationUtils';
import ShowAlert from '../../../utils/ShowAlert';
import { useNotificationHandling } from '../../../utils/NotificationHandling';
import EpisodeTabNavigation from '../EpisodeTabNavigation';

// *************** CUSTOMIZE **************
export default function EpisodeTestScores() {
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();

  const location = useLocation();

  const { episodeOfCare } = location.state;
  const episodeOfCareId = episodeOfCare['id'];
  const startDate = episodeOfCare['start_date'];
  const endDate = episodeOfCare['end_date'];
  const patientId = episodeOfCare['patient_id'];

  let endDateFound = false;
  const datatypeEndDate = typeof endDate;

  if (
    (datatypeEndDate === 'string' && endDate.length === 0) ||
    endDate == null
  ) {
    endDateFound = false;
  } else {
    endDateFound = true;
  }

  //const treatmentStatus = episodeOfCare.status;
  const { role, practiceId } = useContext(UserContext);

  const title = 'Test Scores';
  const subtitle = '';

  const table = 'episode_questionnaire_scores';

  function createRowData(rows) {
    const newId = Math.floor(100000 + Math.random() * 900000);
    return {
      id: newId,
      questionnaire_name: '',
      score_interval: '',
      score_date: '',
      score: 0,
      indication: '',
    };
  }

  // *************** CUSTOMIZE **************

  const [loading, setLoading] = useState(true);
  const [rows, setRawRows] = useState([]);

  const setRows = (rows) => {
    if (!Array.isArray(rows)) {
      return;
    }
    setRawRows(rows.map((r, i) => ({ ...r, no: i + 1 })));
  };

  const query_params = {
    episode_of_care_id: episodeOfCareId,
  };

  useEffect(() => {
    setLoading(true);
    getData(table, query_params)
      .then((data) => {
        setRows(data);
      })
      .catch((error) => {
        handleErrorNotification(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [episodeOfCareId, handleErrorNotification]);

  // Questionnaire Objects
  const [questionnaireObjects, setQuestionnaireObjects] = useState([]);
  const [questionnaireNames, setQuestionnaireNames] = useState([]);

  useEffect(() => {
    getData('questionnaires')
      .then((data) => {
        // Filter out rows where status is not Active
        const activeData = data.filter((row) => row.status === 'Active');
        setQuestionnaireObjects(activeData);
        //const questionnaireNames = activeData.map((obj) => obj.name);
        setQuestionnaireNames(activeData.map((obj) => obj.name));
      })
      .catch((error) => {
        handleErrorNotification(error);
      });
  }, [handleErrorNotification]);

  // Questionnaire_Criteria Objects
  const [questionnaireCriteriaObjects, setQuestionnaireCriteriaObjects] =
    useState([]);

  useEffect(() => {
    getData('questionnaire_criteria')
      .then((data) => {
        setQuestionnaireCriteriaObjects(data);
      })
      .catch((error) => {
        handleErrorNotification(error);
      });
  }, [handleErrorNotification]);

  // Questionnaire_Criteria Objects
  const [
    questionnaireScoreIntervalObjects,
    setQuestionnaireScoreIntervalObjects,
  ] = useState([]);
  const [questionnaireScoreIntervalNames, setQuestionnaireScoreIntervalNames] =
    useState([]);

  useEffect(() => {
    getData('questionnaire_score_intervals')
      .then((data) => {
        setQuestionnaireScoreIntervalObjects(data);
        const activeData = data.filter((row) => row.status === 'Active');
        setQuestionnaireScoreIntervalNames(activeData.map((obj) => obj.name));
      })
      .catch((error) => {
        handleErrorNotification(error);
      });
  }, [handleErrorNotification]);

  const scoreOptions = Array.from({ length: 53 }, (_, index) => index);
  const columns = [
    {
      field: 'questionnaire_name',
      headerName: 'Questionnaire',
      type: 'singleSelect',
      valueOptions: questionnaireNames,
      editable: true,
      flex: 1.0,
      defaultValue: '',
    },
    {
      field: 'questionnaire_score_interval_name',
      headerName: 'Score Interval',
      type: 'singleSelect',
      valueOptions: questionnaireScoreIntervalNames,
      editable: true,
      flex: 0.5,
      defaultValue: '',
    },
    {
      field: 'score_date',
      headerName: 'Date',
      type: 'date',
      width: 180,
      editable: true,
      valueGetter: (params) => {
        const scoreDate = params.row.score_date;
        return typeOfDate(scoreDate, 'object');
      },
    },
    {
      field: 'score',
      headerName: 'Score',
      type: 'singleSelect',
      valueOptions: scoreOptions,
      editable: true,
      defaultValue: 0,
    },
    {
      field: 'indication_at_interval',
      headerName: 'Indication',
      flex: 0.5,
      defaultValue: '',
    },
    {
      field: 'outcome_at_interval',
      headerName: 'Outcome',
      flex: 0.5,
      defaultValue: '',
    },
  ];

  async function validateRow(newRow, oldRow) {
    try {
      const requiredAttributes = [
        'questionnaire_name',
        'questionnaire_score_interval_name',
        'score_date',
        'score',
      ];
      const attributeNames = [
        'Questionnaire',
        'Score Interval',
        'Date',
        'Score',
      ];
      validateRequiredAttributes(requiredAttributes, attributeNames, newRow);

      validateDateDefined(newRow['score_date'], 'Score Date');
      validateNotFutureDate(newRow['score_date'], 'Score Date');

      const scoreDateObject = typeOfDate(newRow.score_date, 'object');
      validateUniqueProperties(rows, newRow, [
        'questionnaire_name',
        'questionnaire_score_interval_name',
      ]);
      validateUniqueProperties(rows, newRow, [
        'questionnaire_name',
        'score_date',
      ]);

      validateDate2GreaterThanOrEqualDate1(
        startDate,
        'Episode of Care Start Date',
        scoreDateObject,
        'Score Date'
      );
      if (endDateFound === true) {
        validateDate2GreaterThanOrEqualDate1(
          scoreDateObject,
          'Score Date',
          endDate,
          'Episode of Care End Date'
        );
      }

      // Validate score in within the min and max score range for the questionnaire
      const criteriaObject = questionnaireCriteriaObjects.find(
        (obj) => obj.questionnaire_name === newRow.questionnaire_name
      );
      const isScoreOutOfRange =
        newRow.score < criteriaObject.min_score ||
        newRow.score > criteriaObject.max_score;

      if (isScoreOutOfRange) {
        console.log(
          `Score ${newRow.score} is out of range for criteria id ${criteriaObject.id}`
        );
        const customError = new Error();
        customError.name = 'Score Error';
        customError.message = `Invalid score for ${criteriaObject.questionnaire_name}. Score must be between ${criteriaObject.min_score} and ${criteriaObject.max_score}.`;
        throw customError;
      }

      const dateString = typeOfDate(newRow.score_date, 'string');
      newRow.score_date = dateString;
      return newRow;
    } catch (error) {
      throw error;
    }
  }

  function getObjectID(
    newRow,
    oldRow,
    keyToCompare,
    objectsArray,
    matchingKey
  ) {
    const object = objectsArray.find(
      (obj) => obj[matchingKey] === newRow[keyToCompare]
    );
    if (object) {
      return object.id;
    } else {
      const customError = new Error();
      customError.name = 'Data Validation Error';
      customError.message = `${keyToCompare} not found.`;
      throw customError;
    }
  }

  async function saveRow(id, row, oldRow, oldRows) {
    try {
      const rowToSave = { ...row };

      if ('questionnaire_id' in rowToSave) {
        rowToSave.isNew = false;
      } else {
        rowToSave.isNew = true;
      }

      const questionnaireId = getObjectID(
        rowToSave,
        oldRow,
        'questionnaire_name',
        questionnaireObjects,
        'name'
      );
      rowToSave.questionnaire_id = questionnaireId;

      const questionnaireScoreIntervalId = getObjectID(
        rowToSave,
        oldRow,
        'questionnaire_score_interval_name',
        questionnaireScoreIntervalObjects,
        'name'
      );
      rowToSave.questionnaire_score_interval_id = questionnaireScoreIntervalId;

      // Convert the date to a string as DB only takes string
      const dateString = typeOfDate(rowToSave.score_date, 'string');
      rowToSave.score_date = dateString;

      if (rowToSave.isNew) {
        delete rowToSave.id;
        rowToSave.episode_of_care_id = episodeOfCareId;
        rowToSave.patient_id = patientId;
        rowToSave.practice_id = practiceId;
        rowToSave['deleted'] = false;
        const data = await postData(table, rowToSave);

        // Update the id to the database id returned
        rowToSave.id = data.data.id;

        // Use the original date object
        rowToSave.score_date = row.score_date;

        // Get duplicate key error without this
        rowToSave.isNew = false;
        const updatedRows = oldRows.map((r) =>
          r.id === id ? { ...rowToSave } : r
        );
        setRows(oldRows.map((r) => (r.id === id ? { ...rowToSave } : r)));
        return rowToSave;
      } else {
        // Convert the date to a string as DB only takes string
        const dateString = typeOfDate(rowToSave.score_date, 'string');
        rowToSave.score_date = dateString;
        await putData(table, rowToSave);

        // Use the original date object
        rowToSave.score_date = row.score_date;
        const updatedRows = oldRows.map((r) =>
          r.id === id ? { ...rowToSave } : r
        );
        setRows(oldRows.map((r) => (r.id === id ? { ...row } : r)));
        console.log('ROWTOSAVE:', rowToSave);
        return rowToSave;
      }
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  async function deleteRow(id, row, oldRows) {
    const rowToSave = { ...row, deleted: true };

    try {
      await putData(table, rowToSave);
      setRows(oldRows.filter((r) => r.id !== id));
      return 'Deleted';
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  if (
    role === 'manager' ||
    role === 'admin' ||
    role === 'super' ||
    role === 'user'
  ) {
    return (
      <div>
        <EpisodeTabNavigation />
        <DataEntry
          title={title}
          subtitle={subtitle}
          columns={columns}
          rows={rows}
          //onRowClick={handleRowClick}
          onValidateRow={validateRow}
          onSaveRow={saveRow}
          onDeleteRow={deleteRow}
          createRowData={createRowData}
          loading={loading}
        />
      </div>
    );
  } else {
    return (
      <div>
        <EpisodeTabNavigation />
        <ViewOnly
          title={title}
          subtitle={subtitle}
          columns={columns}
          rows={rows}
        />
      </div>
    );
  }
}
