import { createContext, useState, useMemo } from 'react';
import { createTheme } from '@mui/material/styles';

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === 'dark'
    ? {
        iconColor: {
          100: '#FFFFFF',
        },
        grey: {
          100: '#e0e0e0',
          200: '#c2c2c2',
          300: '#a3a3a3',
          400: '#858585',
          500: '#666666',
          600: '#525252',
          700: '#3d3d3d',
          800: '#292929',
          900: '#141414',
        },
        primary: {
          100: '#d0d1d5',
          200: '#a1a4ab',
          300: '#727681',
          400: '#1F2A40',
          500: '#141b2d',
          600: '#101624',
          700: '#0c101b',
          800: '#080b12',
          900: '#040509',
        },
        greenAccent: {
          100: '#dbf5ee',
          200: '#b7ebde',
          300: '#94e2cd',
          400: '#70d8bd',
          500: '#4cceac',
          600: '#3da58a',
          700: '#2e7c67',
          800: '#1e5245',
          900: '#0f2922',
        },
        redAccent: {
          100: '#f8dcdb',
          200: '#f1b9b7',
          300: '#e99592',
          400: '#e2726e',
          500: '#db4f4a',
          600: '#af3f3b',
          700: '#832f2c',
          800: '#58201e',
          900: '#2c100f',
        },
        blueAccent: {
          100: '#e1e2fe',
          200: '#c3c6fd',
          300: '#a4a9fc',
          400: '#868dfb',
          500: '#6870fa',
          600: '#535ac8',
          700: '#3e4396',
          800: '#2a2d64',
          900: '#151632',
        },
        orangeAccent: {
          100: '#FFEBD3',
          200: '#FFD1A6',
          300: '#FFB579',
          400: '#FF9E4C',
          500: '#FF871F',
          600: '#FF6F00',
          700: '#E66600',
          800: '#CC5C00',
          900: '#B25300',
        },
        yellowAccent: {
          100: '#FFF9D3',
          200: '#FFF3A6',
          300: '#FFED79',
          400: '#FFE74C',
          500: '#FFE11F',
          600: '#FFDB00',
          700: '#E6C900',
          800: '#CCB300',
          900: '#B29D00',
        },
      }
    : {
        iconColor: {
          100: '#6870fa',
        },
        grey: {
          100: '#333333', // Darker shade for better visibility
          200: '#4d4d4d', // Slightly lighter shade
          300: '#666666', // Standard grey shade
          400: '#7f7f7f', // Lighter shade
          500: '#999999', // Lighter shade
          600: '#b3b3b3', // Lighter shade
          700: '#cccccc', // Lighter shade
          800: '#e6e6e6', // Lighter shade
          900: '#f2f2f2', // Lightest shade
        },
        primary: {
          100: '#040509',
          200: '#080b12',
          300: '#0c101b',
          400: '#f2f0f0', // manually changed
          500: '#141b2d',
          600: '#1F2A40',
          700: '#727681',
          800: '#a1a4ab',
          900: '#d0d1d5',
        },
        greenAccent: {
          100: '#0f2922',
          200: '#1e5245',
          300: '#2e7c67',
          400: '#3da58a',
          500: '#4cceac',
          600: '#70d8bd',
          700: '#94e2cd',
          800: '#b7ebde',
          900: '#dbf5ee',
        },
        redAccent: {
          100: '#2c100f',
          200: '#58201e',
          300: '#832f2c',
          400: '#af3f3b',
          500: '#db4f4a',
          600: '#e2726e',
          700: '#e99592',
          800: '#f1b9b7',
          900: '#f8dcdb',
        },
        blueAccent: {
          100: '#16203b', // Darker shade with more blue
          200: '#2c3d76', // Slightly lighter shade
          300: '#4158b0', // Standard blue shade
          400: '#5676ea', // Lighter shade
          500: '#6c93ff', // Lighter shade
          600: '#8caaff', // Lighter shade
          700: '#a9c2ff', // Lighter shade
          800: '#c7daff', // Lighter shade
          900: '#e5f1ff', // Lightest shade
        },

        orangeAccent: {
          100: '#FFF6E6',
          200: '#FFE6BF',
          300: '#FFD799',
          400: '#FFC173',
          500: '#FFB04C',
          600: '#FFA02B',
          700: '#E68925',
          800: '#CC721E',
          900: '#B25B18',
        },
        yellowAccent: {
          100: '#FFFFCC',
          200: '#FFFF99',
          300: '#FFFF66',
          400: '#FFFF33',
          500: '#FFFF00',
          600: '#FFEC00',
          700: '#E6D900',
          800: '#CCB200',
          900: '#B29900',
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === 'dark'
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: '#fcfcfc',
            },
          }),
    },
    typography: {
      fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
      fontSize: 12,
      h1: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 40,
      },
      h2: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 32,
      },
      h3: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 24,
      },
      h4: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 20,
      },
      h5: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 18,
      },
      h6: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 16,
      },
    },
  };
};

const customSwitchStyleOverrides = {
  MuiSwitch: {
    styleOverrides: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: '#ccc',
      },
      colorPrimary: {
        '&.Mui-checked': {
          // Controls checked color for the thumb
          color: '#f2ff00',
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: '#fff',
        '.Mui-checked.Mui-checked + &': {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: '#fff',
        },
      },
    },
  },
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState('light');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === 'light' ? 'dark' : 'light')),
    }),
    []
  );

  const extendedThemeSettings = {
    ...themeSettings(mode),
    ...customSwitchStyleOverrides,
  };

  const theme = useMemo(() => createTheme(extendedThemeSettings), [mode]);

  return [theme, colorMode];
};
