import * as React from 'react';
import '../../../index.css';
import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import DataEntry from '../../../components/datagrid/dataEntry';
import ViewOnly from '../../../components/datagrid/viewOnly';
import UserContext from '../../../contexts/UserContext';
import { getData, postData, putData } from '../../../utils/API';
import {
  validateRequiredAttributes,
  validateDateDefined,
  validateDate2GreaterThanOrEqualDate1,
  validateNotFutureDate,
  typeOfDate,
  validateUniqueProperties,
} from '../../../utils/ValidationUtils';
import ShowAlert from '../../../utils/ShowAlert';
import { useNotificationHandling } from '../../../utils/NotificationHandling';
import EpisodeTabNavigation from '../EpisodeTabNavigation';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import TMSDiagnosisForm from './TMSDiagnosisForm';

// *************** CUSTOMIZE **************
export default function EpisodeDiagnosis() {
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();

  const location = useLocation();

  const { episodeOfCare } = location.state;
  const episodeOfCareId = episodeOfCare['id'];
  const startDate = episodeOfCare['start_date'];
  const endDate = episodeOfCare['end_date'];
  const patientId = episodeOfCare['patient_id'];

  const orders = [1, 2, 3, 4, 5];

  let endDateFound = false;
  const datatypeEndDate = typeof endDate;

  if (
    (datatypeEndDate === 'string' && endDate.length === 0) ||
    endDate == null
  ) {
    endDateFound = false;
  } else {
    endDateFound = true;
  }

  //const treatmentStatus = episodeOfCare.status;
  const { role, practiceId } = useContext(UserContext);

  const title = 'Episode Diagnosis';
  const subtitle = '';

  const table = 'episode_diagnosis';

  function createRowData(rows) {
    const newId = Math.floor(100000 + Math.random() * 900000);
    return {
      id: newId,
      questionnaire_name: '',
      score_interval: '',
      score_date: '',
      score: 0,
      indication: '',
    };
  }

  // *************** CUSTOMIZE **************

  const [loading, setLoading] = useState(true);
  const [rows, setRawRows] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const setRows = (rows) => {
    if (!Array.isArray(rows)) {
      return;
    }
    setRawRows(rows.map((r, i) => ({ ...r, no: i + 1 })));
  };

  const query_params = {
    episode_of_care_id: episodeOfCareId,
  };
  useEffect(() => {
    setLoading(true);
    getData(table, query_params)
      .then((data) => {
        setRows(data.sort((a, b) => a.dx_order - b.dx_order));
      })
      .catch((error) => {
        handleErrorNotification(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [episodeOfCareId, handleErrorNotification]);

  const getDiagnosisData = () => {
    setLoading(true);
    getData(table, query_params)
      .then((data) => {
        setRows(data.sort((a, b) => a.dx_order - b.dx_order));
      })
      .catch((error) => {
        handleErrorNotification(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Questionnaire Objects
  const [codeObjects, setCodeObjects] = useState([]);
  const [codeNames, setCodeNames] = useState([]);
  const [codeIds, setCodeIds] = useState([]);
  const [codeRange, setCodeRange] = useState([]);
  const [selectedDiagnosisData, setSelectedDiagnosisData] = useState({});
  const [isOrderChange, setIsOrderChange] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState();

  useEffect(() => {
    getData('diagnosis_codes')
      .then((data) => {
        // Filter out rows where status is not Active
        const activeData = data.filter((row) => row.status === 'Active');
        setCodeObjects(activeData);
        //const codes = activeData.map((obj) => obj.name);
        setCodeNames(activeData.map((obj) => obj.code));
        const codeRangeArray = activeData.map((obj) => obj.icd10_code_range);
        setCodeRange(
          codeRangeArray.filter(
            (item, index) => codeRangeArray.indexOf(item) === index
          )
        );
      })
      .catch((error) => {
        handleErrorNotification(error);
      });
  }, [handleErrorNotification]);

  // Questionnaire_Criteria Objects
  const [questionnaireCriteriaObjects, setQuestionnaireCriteriaObjects] =
    useState([]);

  useEffect(() => {
    getData('questionnaire_criteria')
      .then((data) => {
        setQuestionnaireCriteriaObjects(data);
      })
      .catch((error) => {
        handleErrorNotification(error);
      });
  }, [handleErrorNotification]);

  const columns = [
    {
      field: 'dx_order',
      headerName: 'Order',
      type: 'singleSelect',
      valueOptions: [1, 2, 3, 4, 5],
      editable: true,
      flex: 1.0,
      defaultValue: '',
    },
    {
      field: 'diagnosis_code',
      headerName: 'ICD 10 Code',
      type: 'singleSelect',
      valueOptions: ['Baseline', 'First', 'Second', 'Third', 'Final'],
      editable: true,
      flex: 1.5,
      defaultValue: '',
    },
    {
      field: 'diagnosis_category',
      headerName: 'Category',
      editable: true,
      flex: 3,
      defaultValue: 0,
    },
    {
      field: 'diagnosis_long_description',
      headerName: 'Description',
      flex: 5,
      defaultValue: '',
    },
    {
      field: 'start_date',
      headerName: 'Start',
      type: 'date',
      flex: 1.5,
      editable: true,
      valueGetter: (params) => {
        const scoreDate = params.row.start_date;
        return typeOfDate(scoreDate, 'object');
      },
    },
    {
      field: 'end_date',
      headerName: 'End',
      type: 'date',
      flex: 1.5,
      editable: true,
      valueGetter: (params) => {
        const scoreDate = params.row.end_date;
        return typeOfDate(scoreDate, 'object');
      },
    },
  ];

  function getObjectID(
    newRow,
    oldRow,
    keyToCompare,
    objectsArray,
    matchingKey
  ) {
    const object = objectsArray.find(
      (obj) => obj[matchingKey] === newRow[keyToCompare]
    );
    if (object) {
      return object.id;
    } else {
      const customError = new Error();
      customError.name = 'Data Validation Error';
      customError.message = `${keyToCompare} not found.`;
      throw customError;
    }
  }

  const validateRow = async (oldRows, newRow) => {
    const requiredAttributes = ['dx_order'];

    try {
      validateUniqueProperties(oldRows, newRow, requiredAttributes);
    } catch (error) {
      handleErrorNotification(error);
      return true;
    }
    return false;
  };

  const saveDiagnosis = async (newRow) => {
    if (isOrderChange) {
      const isValidate = await validateRow(rows, newRow);
      if (isValidate) {
        return;
      }
    }
    if (isUpdate) {
      newRow.id = selectedRowId;
      putData(table, newRow)
        .then((data) => {
          getDiagnosisData();
        })
        .catch((error) => {
          handleErrorNotification(error);
        });
    } else {
      postData(table, newRow)
        .then((data) => {
          getDiagnosisData();
        })
        .catch((error) => {
          handleErrorNotification(error);
        });
    }

    setIsOpenModal(false);
  };

  const openModal = (id, isUpdate) => {
    let selectedRow = {};
    setIsOpenModal(true);
    if (isUpdate) {
      setSelectedRowId(id);
      setIsUpdate(true);
      selectedRow = rows.find((r) => r.id === id);
      const codeRange = codeObjects.find(
        (code) => code.id === selectedRow.diagnosis_code_id
      );
      selectedRow.codeRange = codeRange.icd10_code_range;
      setSelectedDiagnosisData(selectedRow);
    } else {
      setSelectedDiagnosisData({});
      setIsUpdate(false);
    }
  };

  async function deleteRow(id, row, oldRows) {
    const rowToSave = { ...row, deleted: true };

    try {
      await putData(table, rowToSave);
      setRows(oldRows.filter((r) => r.id !== id));
      return 'Deleted';
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  if (
    role === 'manager' ||
    role === 'admin' ||
    role === 'super' ||
    role === 'user'
  ) {
    return (
      <div>
        <EpisodeTabNavigation />
        <DataEntry
          title={title}
          subtitle={subtitle}
          columns={columns}
          rows={rows}
          onDeleteRow={deleteRow}
          createRowData={createRowData}
          openModal={openModal}
          loading={loading}
          type='form_entry'
        />
        <Dialog open={isOpenModal} onClose={() => setIsOpenModal(false)}>
          <DialogTitle>
            {isUpdate ? 'Edit Diagnosis' : 'Add Diagnosis'}
          </DialogTitle>
          <DialogContent>
            <TMSDiagnosisForm
              onSubmit={(values) => saveDiagnosis(values)}
              onCancel={() => setIsOpenModal(false)}
              practiceId={practiceId}
              episodeOfCareId={episodeOfCareId}
              patientId={patientId}
              codeNames={codeNames}
              codeObjects={codeObjects}
              codeRanges={codeRange}
              selectedDiagnosisData={selectedDiagnosisData}
              orders={orders}
              setOtherOrder={() => setIsOrderChange(true)}
              setSameOrder={() => setIsOrderChange(false)}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  } else {
    return (
      <div>
        <EpisodeTabNavigation />
        <ViewOnly
          title={title}
          subtitle={subtitle}
          columns={columns}
          rows={rows}
        />
      </div>
    );
  }
}
