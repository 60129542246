import React from 'react';
import EpisodeTabNavigation from '../EpisodeTabNavigation';

const EpisodeMedications = () => {
  const title = 'Episode Medications';
  return (
    <div>
      <EpisodeTabNavigation />
      <h1>{title}</h1>
    </div>
  );
};

export default EpisodeMedications;
