import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import ViewOnly from '../../../components/datagrid/viewOnly';
import DataEntry from '../../../components/datagrid/dataEntry';
import UserContext from '../../../contexts/UserContext';
import { getData, postData, putData } from '../../../utils/API';
import {
  validateRequiredAttributes,
  validateEmailFormat,
  validateUniqueEmail,
} from '../../../utils/ValidationUtils';
import ShowAlert from '../../../utils/ShowAlert';
import { useNotificationHandling } from '../../../utils/NotificationHandling';

// *************** CUSTOMIZE ************** START

export default function PracticePractitionersGrid() {
  const { role, practiceId, practice_name } = useContext(UserContext);
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();

  const [title, setTitle] = useState([]);
  const [subtitle, setSubtitle] = useState([]);

  const table = 'practice_practitioners';
  const sort_1 = 'last_name';
  const sort_2 = 'first_name';
  const requiredAttributes = ['last_name', 'first_name', 'email'];
  const attributeNames = ['Last Name', 'First Name', 'Email'];

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    {
      field: 'prefix',
      headerName: 'Prefix',
      editable: true,
      type: 'singleSelect',
      valueOptions: ['Dr.', ''],
      defaultValueGetter: () => '',
      flex: 1,
    },
    {
      field: 'first_name',
      headerName: 'First',
      editable: true,
      flex: 1,
    },
    {
      field: 'last_name',
      headerName: 'Last',
      editable: true,
      flex: 1,
    },
    {
      field: 'suffix',
      headerName: 'Suffix',
      editable: true,
      type: 'singleSelect',
      valueOptions: [
        'M.D.',
        'D.O.',
        'PMHNP',
        'AGNP',
        'ANP',
        'FNP',
        'GNP',
        'LMFT',
        'MFT',
        'NP',
        'PA',
        'PhD',
        'WHNP',
        '',
      ],
      defaultValueGetter: () => '',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: true,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: true,
      headerAlign: 'center',
      align: 'center',
      type: 'singleSelect',
      valueOptions: ['Active', 'Inactive'],
    },
  ];

  const createRowData = (rows) => {
    // IS THIS REDUNDANT, ITS ALSO IN DefaultToolBar
    const newId = Math.floor(100000 + Math.random() * 900000);
    return {
      id: newId,
      practice_id: practiceId,
      user_id: null,
      last_name: '',
      first_name: '',
      suffix: '',
      prefix: '',
      email: '',
      status: 'Active',
      deleted: false,
    };
  };

  // *************** CUSTOMIZE ************** END

  const [loading, setLoading] = useState(true);
  const [rows, setRawRows] = useState([]);

  const setRows = (rows) => {
    if (!Array.isArray(rows)) {
      return;
    }
    setRawRows(rows.map((r, i) => ({ ...r, no: i + 1 })));
  };

  useEffect(() => {
    setLoading(true);
    getData(table, { practice_id: practiceId, deleted: false })
      .then((data) => {
        setTitle(practice_name);
        setSubtitle('Practitioners');
        const sortedItems = sortItems(data, sort_1, sort_2);
        setRows(sortedItems);
      })
      .catch((error) => {
        handleErrorNotification(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [practiceId, handleErrorNotification]);

  function sortItems(items, sort_attribute_1, sort_attribute_2) {
    return items.sort((a, b) => {
      const comparison_1 = a[sort_attribute_1].localeCompare(
        b[sort_attribute_1]
      );

      if (comparison_1 === 0 && sort_attribute_2) {
        return a[sort_attribute_2].localeCompare(b[sort_attribute_2]); // Secondary criterion
      }

      return comparison_1;
    });
  }

  async function validateRow(newRow, oldRow) {
    try {
      validateRequiredAttributes(requiredAttributes, attributeNames, newRow);
      validateEmailFormat(newRow.email);
      validateUniqueEmail(rows, newRow);
      return newRow;
    } catch (error) {
      throw error;
    }
  }

  async function saveRow(id, row, oldRow, oldRows) {
    try {
      if (row.isNew) {
        const rowToSave = { ...row, practice_id: practiceId };
        delete rowToSave.id;
        rowToSave['active'] = 'Active';
        const data = await postData(table, rowToSave);
        // Add the id returned from the database
        rowToSave.id = data.data.id;

        // Get duplicate key error without this
        rowToSave.isNew = false;
        setRows(oldRows.map((r) => (r.id === id ? { ...rowToSave } : r)));
        return rowToSave;
      } else {
        await putData(table, row);
        return row;
      }
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  async function episodesOfCareExists(row) {
    try {
      const result = await getData('episodes_of_care', {
        practice_id: practiceId,
        practitioner_id: row.id,
        deleted: false,
      });
      if (result.length === 0) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return true;
    }
  }

  async function deleteRow(id, row, oldRows) {
    const episodeExists = await episodesOfCareExists(row);
    if (episodeExists) {
      let fullName = `${row.first_name} ${row.last_name}`;
      const customError = new Error();
      customError.name = 'Delete Error';
      customError.message = `${fullName} has treated patients and cannot be deleted.\nSet the status to Inactive to hide the practitioner.`;
      handleErrorNotification(customError);
      return;
    }

    let rowToDelete = { ...row, deleted: true };
    try {
      await putData(table, rowToDelete);
      setRows(oldRows.filter((r) => r.id !== id));
      return 'Deleted';
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  if (notificationState.showError) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  if (role === 'manager' || role === 'admin' || role === 'super') {
    return (
      <div>
        <DataEntry
          title={title}
          subtitle={subtitle}
          columns={columns}
          rows={rows}
          onValidateRow={validateRow}
          onSaveRow={saveRow}
          onDeleteRow={deleteRow}
          createRowData={createRowData}
          loading={loading}
        />
      </div>
    );
  } else {
    return (
      <div>
        <ViewOnly
          title={title}
          subtitle={subtitle}
          columns={columns}
          rows={rows}
          loading={loading}
        />
      </div>
    );
  }
}
