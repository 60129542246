export function parseUTCDate(dateStr) {
  const [year, month, day] = dateStr.split('-').map(Number);
  return new Date(Date.UTC(year, month - 1, day)); // Remember that months are 0-indexed in JS
}

export function formatDateToMMDDYYYY(date) {
  //const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  //return date.toLocaleDateString('en-US', options);

  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = String(date.getFullYear());

  return `${year}-${month}-${day}`;

  // This code was creating situationwhere the date in the database wasn't displayed
  // in the datagrid with the same date.  We need to display the date as it is in the
  // database

  //const day = String(date.getUTCDate()).padStart(2, '0');
  //const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // +1 because months are 0-indexed.
  //const year = date.getUTCFullYear();
  //return `${month}-${day}-${year}`;
}

export function dateStringToDate(dateString) {
  // Assuming medication.date_prescribed is in a valid date format
  const datePrescribed = new Date(dateString);

  // Get the components of the date
  const year = datePrescribed.getFullYear();
  const month = datePrescribed.getMonth(); // Months are zero-based
  const day = datePrescribed.getDate();

  // Create a new Date object with the components
  const formattedDate = new Date(year, month, day);
  return formattedDate;
}

export function getYear(date) {
  let dateObject = new Date();
  if (date instanceof Date) {
    dateObject = date;
  } else {
    dateObject = new Date(date);
  }
  if (isNaN(dateObject.getTime())) {
    return 0;
  }
  return dateObject.getFullYear();
}

export function getMonth(date) {
  let dateObject = new Date();
  if (date instanceof Date) {
    dateObject = date;
  } else {
    dateObject = new Date(date);
  }
  if (isNaN(dateObject.getTime())) {
    return 0;
  }

  // Javascript returns a zero based month for Jan
  return dateObject.getMonth() + 1;
}

export function getDayNumber(date) {
  let dateObject = new Date();
  if (date instanceof Date) {
    dateObject = date;
  } else {
    dateObject = new Date(date);
  }
  if (isNaN(dateObject.getTime())) {
    return 0;
  }
  return dateObject.getDate();
}

export function getMinutes(date) {
  let dateObject = new Date();
  if (date instanceof Date) {
    dateObject = date;
  } else {
    dateObject = new Date(date);
  }
  if (isNaN(dateObject.getTime())) {
    return 0;
  }
  return dateObject.getMinutes();
}

export function getHours(date) {
  let dateObject = new Date();
  if (date instanceof Date) {
    dateObject = date;
  } else {
    dateObject = new Date(date);
  }
  if (isNaN(dateObject.getTime())) {
    return 0;
  }
  return dateObject.getHours();
}

export function getDayOfWeek(date) {
  let dateObject = new Date();
  if (date instanceof Date) {
    dateObject = date;
  } else {
    dateObject = new Date(date);
  }
  if (isNaN(dateObject.getTime())) {
    return 8;
  }
  return dateObject.getDay();
}

export function getQuarter(date) {
  let dateObject = new Date();
  if (date instanceof Date) {
    dateObject = date;
  } else {
    dateObject = new Date(date);
  }
  if (isNaN(dateObject.getTime())) {
    return 0;
  }

  const month = dateObject.getMonth() + 1; // JavaScript months are 0-based
  return Math.ceil(month / 3);
}

export function getYesterday() {
  // Create a new Date object for today
  const today = new Date();

  // Subtract one day from today to get yesterday
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  // Get the year, month, and day components of yesterday
  const yyyy = yesterday.getFullYear();
  const mm = String(yesterday.getMonth() + 1).padStart(2, '0'); // Month is 0-based, so add 1
  const dd = String(yesterday.getDate()).padStart(2, '0');

  // Create the 'yyyy-mm-dd' formatted string for yesterday
  const yesterdayDateString = `${yyyy}-${mm}-${dd}`;
  return yesterdayDateString;
}

export function calculateAge(start_date, birth_date) {
  // Check if start_date is a string, if so, convert to Date
  const startDate =
    typeof start_date === 'string' ? new Date(start_date) : start_date;
  // Check if birth_date is a string, if so, convert to Date
  const birthDate =
    typeof birth_date === 'string' ? new Date(birth_date) : birth_date;

  let age = startDate.getFullYear() - birthDate.getFullYear();
  const monthDifference = startDate.getMonth() - birthDate.getMonth();

  // If start date is before the birth date anniversary for the year, subtract 1 from age
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && startDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}
