// src/AppRoutes.js
import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PatientSearch from './pages/patientSearch';

import ProviderFollowUpDataGrid from './pages/dashboards/providers/followUpDataGrid';
import PracticeFollowUpDataGrid from './pages/dashboards/practice/followUpDataGrid';

import EpisodesDataGrid from './pages/patientData/episodes';
import EpisodeDashboard from './pages/patientData/episodeDashboard';
import EpisodeTMSTreatmentSessions from './pages/patientData/episodeTMSTreatmentSessions';
import EpisodeTMSTreatmentPlans from './pages/patientData/episodeTMSTreatmentPlans';
import EpisodeTestScores from './pages/patientData/episodeTestScores';
import EpisodeMedications from './pages/patientData/episodeMedications';
import EpisodeDiagnosis from './pages/patientData/episodeDiagnosis';

import PatientDashboard from './pages/dashboards/patientDashboard';

import PracticeEpisodesDataGrid from './pages/master_data/practice_episodes';
import PracticePatientsGrid from './pages/master_data/practice_patients';
import PracticeOfficesGrid from './pages/master_data/practice_offices';
import PracticePractitionersGrid from './pages/master_data/practice_practitioners';
import PracticeTMSTreatmentPlan from './pages/master_data/practice_tms_treatment_plans';
import PracticeTMSProtocolsGrid from './pages/master_data/practice_tms_protocols';
import PracticeTMSCoilsGrid from './pages/master_data/practice_tms_coils';
import PracticeTMSDevicesGrid from './pages/master_data/practice_tms_devices';
import PracticeUsersGrid from './pages/master_data/practice_users';
import PracticeIntegrationsGrid from './pages/master_data/practice_integrations';
import ActiveIngredientsGrid from './pages/reference_data/active_ingredients';
import IntegrationTypesGrid from './pages/reference_data/integration_types';
import IntegrationVendorsGrid from './pages/reference_data/integration_vendors';
import DiagnosisCodesGrid from './pages/reference_data/diagnosis_codes';
import DisordersGrid from './pages/reference_data/disorders';
import ProcedureCategoriesGrid from './pages/reference_data/procedure_categories';
import ProcedureCodesGrid from './pages/reference_data/procedure_codes';
import AppointmentTypesGrid from './pages/reference_data/appointment_types';
import TMSDevicesGrid from './pages/reference_data/tms_devices';
import TMSCoilsGrid from './pages/reference_data/tms_coils';
import TMSFrequenciesGrid from './pages/reference_data/tms_frequencies';
import TMSPulseTypesGrid from './pages/reference_data/tms_pulse_types';
import TMSStimlationSitesGrid from './pages/reference_data/tms_stimulation_sites';
import TMSProtocolGrid from './pages/reference_data/tms_protocols';
import RolesGrid from './pages/reference_data/roles';
import BiomarkerTypesGrid from './pages/reference_data/biomarker_types';
import BiomarkersGrid from './pages/reference_data/biomarkers';
import Form from './pages/Form';
import Bar from './pages/Bar';
import Line from './pages/Line';
import Pie from './pages/Pie';
import FAQ from './pages/Faq';
import Geography from './pages/Geography';
import Calendar from './pages/Calendar';
import Login from './pages/Login';
import { RequireAuth } from './utils/RequireAuth';
import { Auth } from 'aws-amplify';

function AppRoutes({ isAuthenticated, handleSuccessfulLogin }) {
  return (
    <Routes>
      <Route index element={<PatientSearch />} />
      <Route path='/' element={<PatientSearch />} />
      <Route
        path='/login'
        element={
          isAuthenticated ? (
            <Navigate to='/' />
          ) : (
            <Login onSuccessfulLogin={handleSuccessfulLogin} />
          )
        }
      />
      <Route
        path='/provider/follow_ups'
        element={
          <RequireAuth>
            <ProviderFollowUpDataGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/practice/follow_ups'
        element={
          <RequireAuth>
            <PracticeFollowUpDataGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/patient/dashboard'
        element={
          <RequireAuth>
            <PatientDashboard />
          </RequireAuth>
        }
      />
      <Route
        path='/patient/episodes'
        element={
          <RequireAuth>
            <EpisodesDataGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/patient/episode/dashboard'
        element={
          <RequireAuth>
            <EpisodeDashboard />
          </RequireAuth>
        }
      />
      <Route
        path='/patient/episode/tms_treatment_plans'
        element={
          <RequireAuth>
            <EpisodeTMSTreatmentPlans />
          </RequireAuth>
        }
      />
      {/* <Route
        path='/patient/episode/tms_treatment_sessions'
        element={
          <RequireAuth>
            <EpisodeTMSTreatmentSessions />
          </RequireAuth>
        }
      /> */}
      <Route
        path='/patient/episode/test_scores'
        element={
          <RequireAuth>
            <EpisodeTestScores />
          </RequireAuth>
        }
      />
      <Route
        path='/patient/episode/diagnosis'
        element={
          <RequireAuth>
            <EpisodeDiagnosis />
          </RequireAuth>
        }
      />
      <Route
        path='/patient/episode/medications'
        element={
          <RequireAuth>
            <EpisodeMedications />
          </RequireAuth>
        }
      />
      <Route
        path='/patient/search'
        element={
          <RequireAuth>
            <PatientSearch />
          </RequireAuth>
        }
      />
      <Route
        path='/practice/offices'
        element={
          <RequireAuth>
            <PracticeOfficesGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/practice/episodes'
        element={
          <RequireAuth>
            <PracticeEpisodesDataGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/practice/integrations'
        element={
          <RequireAuth>
            <PracticeIntegrationsGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/practice/practitioners'
        element={
          <RequireAuth>
            <PracticePractitionersGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/practice/patients'
        element={
          <RequireAuth>
            <PracticePatientsGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/practice/tms_treatment_plans'
        element={
          <RequireAuth>
            <PracticeTMSTreatmentPlan />
          </RequireAuth>
        }
      />
      <Route
        path='/practice/tms_coils'
        element={
          <RequireAuth>
            <PracticeTMSCoilsGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/practice/tms_devices'
        element={
          <RequireAuth>
            <PracticeTMSDevicesGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/practice/tms_protocols'
        element={
          <RequireAuth>
            <PracticeTMSProtocolsGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/practice/users'
        element={
          <RequireAuth>
            <PracticeUsersGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/reference_data/tms_protocols'
        element={
          <RequireAuth>
            <TMSProtocolGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/reference_data/active_ingredients'
        element={
          <RequireAuth>
            <ActiveIngredientsGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/reference_data/integration_types'
        element={
          <RequireAuth>
            <IntegrationTypesGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/reference_data/integration_vendors'
        element={
          <RequireAuth>
            <IntegrationVendorsGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/reference_data/diagnosis_codes'
        element={
          <RequireAuth>
            <DiagnosisCodesGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/reference_data/disorders'
        element={
          <RequireAuth>
            <DisordersGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/reference_data/procedure_categories'
        element={
          <RequireAuth>
            <ProcedureCategoriesGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/reference_data/procedure_codes'
        element={
          <RequireAuth>
            <ProcedureCodesGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/reference_data/appointment_types'
        element={
          <RequireAuth>
            <AppointmentTypesGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/reference_data/tms_devices'
        element={
          <RequireAuth>
            <TMSDevicesGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/reference_data/tms_coils'
        element={
          <RequireAuth>
            <TMSCoilsGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/reference_data/tms_frequencies'
        element={
          <RequireAuth>
            <TMSFrequenciesGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/reference_data/tms_pulse_types'
        element={
          <RequireAuth>
            <TMSPulseTypesGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/reference_data/tms_stimulation_sites'
        element={
          <RequireAuth>
            <TMSStimlationSitesGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/reference_data/biomarker_types'
        element={
          <RequireAuth>
            <BiomarkerTypesGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/reference_data/biomarkers'
        element={
          <RequireAuth>
            <BiomarkersGrid />
          </RequireAuth>
        }
      />
      <Route
        path='/reference_data/user_roles'
        element={
          <RequireAuth>
            <RolesGrid />
          </RequireAuth>
        }
      />
      <Route path='/form' element={<Form />} />
      <Route path='/bar' element={<Bar />} />
      <Route path='/pie' element={<Pie />} />
      <Route path='/line' element={<Line />} />
      <Route path='/faq' element={<FAQ />} />
      <Route path='/calendar' element={<Calendar />} />
      <Route path='/geography' element={<Geography />} />
    </Routes>
  );
}

export default AppRoutes;
