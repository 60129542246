// practice_episodes/index.jsx

import * as React from 'react';
import '../../../index.css';
import { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import DataEntry from '../../../components/datagrid/dataEntry';
import ViewOnly from '../../../components/datagrid/viewOnly';
import UserContext from '../../../contexts/UserContext';
import { getData, postData, putData } from '../../../utils/API';
import {
  validateRequiredAttributes,
  validateDateDefined,
  validateDateNotDefined,
  validateEndDateGreaterThanStartDate,
  validateNotFutureDate,
  typeOfDate,
} from '../../../utils/ValidationUtils';
import ShowAlert from '../../../utils/ShowAlert';
import { useNotificationHandling } from '../../../utils/NotificationHandling';
import logger from '../../../utils/logger';

// *************** CUSTOMIZE **************
export default function PracticeEpisodesDataGrid() {
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();

  const navigate = useNavigate();

  const { role, practiceId, practice_name } = useContext(UserContext);

  const title = practice_name;
  const subtitle = 'Episodes of Care';

  const table = 'episodes_of_care';

  function createRowData(rows) {
    const newId = Math.floor(100000 + Math.random() * 900000);
    return {
      id: newId,
      office_name: '',
      practitioner_full_name: '',
      status: 'In Progress',
      start_date: '',
      end_date: '',
      age: '',
    };
  }

  // *************** CUSTOMIZE **************

  const [loading, setLoading] = useState(true);
  const [rows, setRawRows] = useState([]);

  const setRows = (rows) => {
    if (!Array.isArray(rows)) {
      return;
    }
    setRawRows(rows.map((r, i) => ({ ...r, no: i + 1 })));
  };

  const query_params = {
    deleted: false,
  };

  useEffect(() => {
    setLoading(true);
    getData('episodes_of_care', query_params)
      .then((data) => {
        setRows(data);
      })
      .catch((error) => {
        handleErrorNotification(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleErrorNotification]);

  const query_params_ref_data = { deleted: false, status: 'Active' };

  const [officeObjects, setOfficeObjects] = useState([]);
  const [officeNames, setOfficeNames] = useState([]);

  useEffect(() => {
    setLoading(true);
    getData('practice_offices', query_params_ref_data)
      .then((data) => {
        setOfficeObjects(data);
        const names = data.map((office) => office.name);
        setOfficeNames(names);
      })
      .catch((error) => {
        handleErrorNotification(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleErrorNotification]);

  const [practitionerObjects, setPractitionerObjects] = useState([]);
  const [practitionerNames, setPractitionerNames] = useState([]);

  useEffect(() => {
    setLoading(true);
    getData('practice_practitioners', query_params_ref_data)
      .then((data) => {
        setPractitionerObjects(data);
        const names = data.map((practitioner) => practitioner.full_name);
        setPractitionerNames(names);
      })
      .catch((error) => {
        handleErrorNotification(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleErrorNotification]);

  // Handle row click
  const handleRowClick = (params) => {
    const currentRow = { ...params.row };

    const startDate = typeOfDate(currentRow.start_date, 'string');
    currentRow.start_date = startDate;

    if (
      typeof currentRow.end_date !== 'undefined' &&
      currentRow.end_date !== null
    ) {
      const endDate = typeOfDate(currentRow.end_date, 'string');
      currentRow.end_date = endDate;
    } else {
      currentRow.endDate = ' ';
    }

    currentRow.lastName = currentRow.last_name;
    currentRow.firstName = currentRow.first_name;

    navigate('/patient/episode/dashboard', {
      state: { episodeOfCare: currentRow },
    });
  };

  const columns = [
    {
      field: 'full_name',
      headerName: 'Patient',
      type: 'singleSelect',
      editable: false,
      flex: 0.5,
      defaultValue: '',
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      flex: 0.5,
      defaultValue: '',
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      flex: 0.5,
      defaultValue: '',
    },
    {
      field: 'age',
      headerName: 'Age',
      flex: 0.5,
      defaultValue: '',
    },
    {
      field: 'office_name',
      headerName: 'Office',
      type: 'singleSelect',
      valueOptions: officeNames,
      editable: true,
      flex: 0.5,
      defaultValue: '',
    },
    {
      field: 'practitioner_full_name',
      headerName: 'Practitioner',
      type: 'singleSelect',
      valueOptions: practitionerNames,
      editable: true,
      flex: 0.5,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: ['In Progress', 'Completed', 'Discontinued', 'Terminated'],
      editable: true,
      flex: 0.5,
    },
    {
      field: 'acute_phase_sessions',
      headerName: 'Acute Phase Sessions',
      type: 'singleSelect',
      valueOptions: [
        10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
        28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
      ],
      editable: true,
      flex: 0.5,
    },
    {
      field: 'start_date',
      headerName: 'Start',
      type: 'date',
      width: 100,
      editable: true,
      valueGetter: (params) => {
        // On the first render start_date is a string, on subsequent renders its a date object
        const startDate = params.row.start_date;
        const startDateObject = typeOfDate(startDate, 'object');
        return startDateObject;
      },
    },
    {
      field: 'end_date',
      headerName: 'End',
      type: 'date',
      width: 100,
      editable: true,
      valueGetter: (params) => {
        // BE CAREFUL CHANGING THIS - WAS DIFFICULT TO MAKE WORK
        // ACROSS ALL USE CASES
        const endDate = params.row.end_date;
        if (endDate instanceof Date) {
          if (!isNaN(Date.parse(endDate))) {
            return endDate;
          } else {
            return '';
          }
        }

        if (typeof endDate === 'string') {
          const endDateString = endDate.replace(/-/g, '/');
          const endDateObject = new Date(endDateString);
          if (!isNaN(endDateObject.getTime())) {
            return endDateObject;
          } else {
            return;
          }
        }
      },
    },
  ];

  /*
  const handleAddTestScore = (row) => {
    console.log('handleAddTestScore:', row);
    navigate('/patient/episode/test_scores', {
      state: { episodeOfCare: row },
    });
  };

  const handleAddTreatmentSession = (row) => {
    console.log('handleAddTestScore:', row);
    navigate('/patient/episode/tms_treatment_sessions', {
      state: { episodeOfCare: row },
    });
  };
  */

  async function validateRow(newRow, oldRow) {
    try {
      const requiredAttributes = [
        'office_name',
        'practitioner_full_name',
        'acute_phase_sessions',
        'status',
      ];
      const attributeNames = [
        'Office',
        'Practitioner',
        'Acute Phase Sessions',
        'Status',
      ];
      validateRequiredAttributes(requiredAttributes, attributeNames, newRow);
      validateDateDefined(newRow['start_date'], 'Start Date');
      validateNotFutureDate(newRow['start_date'], 'Start Date');

      if (newRow.status === 'In Progress') {
        validateDateNotDefined(newRow['end_date'], 'End Date');
      } else {
        validateDateDefined(newRow['end_date'], 'End Date');
        validateEndDateGreaterThanStartDate(
          newRow['start_date'],
          newRow['end_date']
        );
        validateNotFutureDate(newRow['end_date'], 'End Date');
      }

      const episodeInProgress = episodesOfCareInProgressExists(rows);
      if (episodeInProgress === true) {
        const customError = new Error();
        customError.name = 'Data Validation Error';
        customError.message = `A patient can only have one episode of care with the status of In Progress.`;
        throw customError;
      }
      return newRow;
    } catch (error) {
      throw error;
    }
  }

  function getObjectID(
    newRow,
    oldRow,
    keyToCompare,
    objectsArray,
    matchingKey
  ) {
    const object = objectsArray.find(
      (obj) => obj[matchingKey] === newRow[keyToCompare]
    );
    if (object) {
      return object.id;
    } else {
      const customError = new Error();
      customError.name = 'Data Validation Error';
      customError.message = `${keyToCompare} not found.`;
      throw customError;
    }
  }

  async function saveRow(id, row, oldRow, oldRows) {
    try {
      const rowToSave = { ...row };

      const startDateString = typeOfDate(row.start_date, 'string');
      rowToSave.start_date = startDateString;

      if (rowToSave.status === 'In Progress') {
        delete rowToSave.end_date;
      } else {
        const endDateString = typeOfDate(rowToSave.end_date, 'string');
        rowToSave.end_date = endDateString;
      }

      const officeId = getObjectID(
        rowToSave,
        oldRow,
        'office_name',
        officeObjects,
        'name'
      );
      rowToSave.office_id = officeId;

      const practitionerId = getObjectID(
        rowToSave,
        oldRow,
        'practitioner_full_name',
        practitionerObjects,
        'full_name'
      );
      rowToSave.practitioner_id = practitionerId;

      if (rowToSave.isNew) {
        delete rowToSave.id;
        rowToSave.practice_id = practiceId;
        rowToSave.deleted = false;

        logger.info(`rowToSave: ${rowToSave}`);
        const data = await postData(table, rowToSave);
        rowToSave.id = data.data.id;

        // Use the original date object
        rowToSave.start_date = row.start_date;

        // Get duplicate key error without this
        rowToSave.isNew = false;

        // Add last and first name
        //rowToSave.last_name = lastName;
        //rowToSave.first_name = firstName;

        setRows(oldRows.map((r) => (r.id === id ? { ...rowToSave } : r)));
        return rowToSave;
      } else {
        await putData(table, rowToSave);
        rowToSave.start_date = row.startDate;
        if ('endDate' in rowToSave) {
          rowToSave.end_date = row.end_date;
        }

        setRows(oldRows.map((r) => (r.id === id ? { ...row } : r)));
        return rowToSave;
      }
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  async function episodesTestScoreExists(row) {
    try {
      const query_params = { episode_of_care_id: row.id, deleted: false };
      const result = await getData(
        'episode_questionnaire_scores',
        query_params
      );
      if (result.length === 0) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return true;
    }
  }

  async function episodesTreatmentPlansExists(row) {
    try {
      const query_params = { episode_of_care_id: row.id, deleted: false };
      const result = await getData('episode_tms_treatment_plans', query_params);
      if (result.length === 0) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return true;
    }
  }

  async function episodesTreatmentSessionExists(row) {
    try {
      const query_params = { episode_of_care_id: row.id, deleted: false };
      const result = await getData(
        'episode_tms_treatment_sessions',
        query_params
      );
      if (result.length === 0) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return true;
    }
  }

  async function episodesOfCareInProgressExists(rows) {
    let countInProgress = 0;
    rows.forEach((row, index) => {
      if (row['status'] === 'In Progress') {
        countInProgress++;
      }
    });
    if (countInProgress > 1) {
      return true;
    } else {
      return false;
    }
  }

  async function deleteRow(id, row, oldRows) {
    const treatmentPlansExists = await episodesTreatmentPlansExists(row);
    if (treatmentPlansExists) {
      setRows(oldRows);
      const customError = new Error();
      customError.name = 'Delete Error';
      customError.message = `This Episode of Care has Treatment Plans. Delete the Treatment Plans before deleting the Episode of Care.`;
      handleErrorNotification(customError);
      return;
    }

    // Need to create an error object for this condition
    const treatmentSessionExists = await episodesTreatmentSessionExists(row);
    if (treatmentSessionExists) {
      setRows(oldRows);
      const customError = new Error();
      customError.name = 'Delete Error';
      customError.message = `This Episode of Care has Treatment Sessions. Delete the Treatment Sessions before deleting the Episode of Care.`;
      handleErrorNotification(customError);
      return;
    }

    const testScoreExists = await episodesTestScoreExists(row);
    if (testScoreExists) {
      setRows(oldRows);
      const customError = new Error();
      customError.name = 'Delete Error';
      customError.message = `This Episode of Care has test scores. Delete the test scores before deleting the Episode of Care.`;
      handleErrorNotification(customError);
      return;
    }

    const rowToSave = { ...row, deleted: true };
    delete rowToSave.start_date;
    delete rowToSave.end_date;

    try {
      await putData(table, rowToSave);
      setRows(oldRows.filter((r) => r.id !== id));
      return 'Deleted';
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  if (
    role === 'manager' ||
    role === 'admin' ||
    role === 'super' ||
    role === 'user'
  ) {
    return (
      <div>
        <DataEntry
          title={title}
          subtitle={subtitle}
          columns={columns}
          rows={rows}
          onValidateRow={validateRow}
          onSaveRow={saveRow}
          onDeleteRow={deleteRow}
          createRowData={createRowData}
          onRowClick={handleRowClick}
          loading={loading}
          hideAddIcon={true}
        />
      </div>
    );
  } else {
    return (
      <div>
        <ViewOnly
          title={title}
          subtitle={subtitle}
          columns={columns}
          rows={rows}
        />
      </div>
    );
  }
}
