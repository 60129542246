import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import ViewOnly from '../../../components/datagrid/viewOnly';
import DataEntry from '../../../components/datagrid/dataEntry';
import UserContext from '../../../contexts/UserContext';
import { getData, postData, putData } from '../../../utils/API';
import {
  validateRequiredAttributes,
  validateUniqueProperty,
  validateUniqueEmail,
} from '../../../utils/ValidationUtils';
import ShowAlert from '../../../utils/ShowAlert';
import { useNotificationHandling } from '../../../utils/NotificationHandling';

// *************** CUSTOMIZE ************** START

export default function PracticeUsersGrid() {
  const { role, practiceId, practice_name } = useContext(UserContext);
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();

  const title = 'Users';
  let subtitle = `View ${title}`;
  if (role === 'admin' || role === 'super') {
    subtitle = 'Add, Edit, Delete';
  }

  const table = 'practice_users';
  const sort_1 = 'last_name';
  const sort_2 = 'first_name';
  const requiredAttributes = ['role_id', 'last_name', 'first_name', 'email'];
  const attributeNames = ['Role', 'Last Name', 'First Name', 'Email'];

  function createRowData(rows) {
    // IS THIS REDUNDANT, ITS ALSO IN DefaultToolBar
    const newId = Math.floor(100000 + Math.random() * 900000);
    return {
      id: newId,
      last_name: '',
      first_name: '',
      role: 'User',
      email: '',
      cognito_id: '',
      status: 'Active',
    };
  }
  // *************** CUSTOMIZE ************** END

  const [loading, setLoading] = useState(true);
  const [rows, setRawRows] = useState([]);

  const setRows = (rows) => {
    if (!Array.isArray(rows)) {
      return;
    }
    setRawRows(rows.map((r, i) => ({ ...r, no: i + 1 })));
  };

  useEffect(() => {
    if (!practiceId || practiceId === '') {
      // Exit early if practiceId is empty or falsy
      return;
    }

    setLoading(true);
    getData(table, { practice_id: practiceId, deleted: false })
      .then((data) => {
        // Filter out super role
        const userData = data.filter((row) => row.role_id !== 4);

        const sortedItems = sortItems(userData, sort_1, sort_2);
        setRows(sortedItems);
      })
      .catch((error) => {
        handleErrorNotification(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [practiceId, handleErrorNotification]);

  const [roleObjects, setRoleObjects] = useState([]);
  const [roleNames, setRoleNames] = useState([]);

  // Roles
  useEffect(() => {
    setLoading(true);
    getData('roles')
      .then((data) => {
        // Filter out rows where super role
        const roleData = data.filter((row) => row.name !== 'super');

        // Filter out rows where status is not Active
        const activeData = roleData.filter((row) => row.status === 'Active');

        const sortedNames = activeData.map((obj) => obj.name).sort();
        //console.log(roleData, 'roleData');
        setRoleNames(sortedNames);
        setRoleObjects(activeData);
      })
      .catch((error) => {
        handleErrorNotification(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleErrorNotification]);

  function sortItems(items, sort_attribute_1, sort_attribute_2) {
    return items.sort((a, b) => {
      // Primary criterion: sort_attribute_1
      const comparison_1 = a[sort_attribute_1].localeCompare(
        b[sort_attribute_1]
      );

      // If the primary criteria are the same and sort_attribute_2 is provided, sort by sort_attribute_2
      if (comparison_1 === 0 && sort_attribute_2) {
        return a[sort_attribute_2].localeCompare(b[sort_attribute_2]); // Secondary criterion
      }

      return comparison_1;
    });
  }

  async function validateRow(newRow, oldRows) {
    try {
      validateRequiredAttributes(requiredAttributes, attributeNames, newRow);
      validateUniqueProperty(rows, newRow, 'cognito_id');
      validateUniqueEmail(rows, newRow);
      const validateRow = { ...newRow };
      const roleObject = roleObjects.find(
        (obj) => obj.name === validateRow.role_name
      );
      validateRow.role_id = roleObject.id;
      return validateRow;
    } catch (error) {
      throw error;
    }
  }

  async function saveRow(id, row, oldRow, oldRows) {
    try {
      if (row.isNew) {
        const rowToSave = { ...row, practice_id: practiceId, deleted: false };
        delete rowToSave.id;
        rowToSave['active'] = 'Active';
        const data = await postData(table, rowToSave);
        // Add the id returned from the database
        rowToSave.id = data.data.id;
        // Get duplicate key error without this
        rowToSave.isNew = false;
        setRows(oldRows.map((r) => (r.id === id ? { ...rowToSave } : r)));
        return rowToSave;
      } else {
        await putData(table, row);
        setRows(oldRows.map((r) => (r.id === id ? { ...row } : r)));
        return row;
      }
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  async function deleteRow(id, row, oldRows) {
    const rowToSave = { ...row, deleted: true };

    try {
      await putData(table, rowToSave);
      setRows(oldRows.filter((r) => r.id !== id));
      return 'Deleted';
    } catch (error) {
      setRows(oldRows);
      throw error;
    }
  }

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    {
      field: 'last_name',
      headerName: 'Last Name',
      editable: false,
      cellClassName: 'name-column--cell',
      flex: 0.5,
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      editable: false,
      cellClassName: 'name-column--cell',
      flex: 0.5,
    },
    {
      field: 'role_name',
      headerName: 'Role',
      editable: true,
      type: 'singleSelect',
      valueOptions: roleNames,
      flex: 0.5,
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      flex: 1,
    },
    {
      field: 'cognito_id',
      headerName: 'Cognito_id',
      editable: false,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: true,
      headerAlign: 'center',
      align: 'center',
      type: 'singleSelect',
      valueOptions: ['Active', 'Inactive'],
      defaultValueGetter: () => 'Active',
      flex: 0.5,
    },
  ];

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  if (role === 'admin' || role === 'super') {
    return (
      <div>
        <DataEntry
          title={title}
          subtitle={subtitle}
          columns={columns}
          rows={rows}
          onValidateRow={validateRow}
          onSaveRow={saveRow}
          onDeleteRow={deleteRow}
          createRowData={createRowData}
          loading={loading}
        />
      </div>
    );
  } else {
    return (
      <div>
        <ViewOnly
          title={title}
          subtitle={subtitle}
          columns={columns}
          rows={rows}
          loading={loading}
        />
      </div>
    );
  }
}
