import React from "react";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const LineChart = ({ labels, dataSets }) => {

  const calculateYMax = (maxValue) => {
    if (maxValue <= 30) return 30;
    if (maxValue <= 40) return 40;
    if (maxValue <= 50) return 50;
    return 60;
  };

  const getVisibleMaxValue = (chart) => {
    const visibleDataSets = dataSets.filter((dataset, index) => !chart.getDatasetMeta(index).hidden);
    const allValues = visibleDataSets.flatMap(dataset => dataset.data);
    return Math.max(...allValues);
  };

  const data = {
    labels: labels,
    datasets: dataSets
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
        onClick: (e, legendItem, legend) => {
          const index = legendItem.datasetIndex;
          const ci = legend.chart;
          const meta = ci.getDatasetMeta(index);

          meta.hidden = !meta.hidden;

          const maxValue = calculateYMax(getVisibleMaxValue(ci));
          console.log(maxValue)

          const yScale = ci.scales.y;
          yScale.options.max = maxValue;

          ci.update();
          console.log(ci, 'chart')
        }
      },
      title: {
        display: true,
        text: "Test Scores",
      },
      tooltip: {
        caretPadding: 10
      },
      datalabels: {
        display: true,
        color: "white",
        align: "end",
        anchor: "end",
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default LineChart;
