import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Box,
  Autocomplete,
} from "@mui/material";
import ShowAlert from "../../../utils/ShowAlert";
import { useNotificationHandling } from "../../../utils/NotificationHandling";

const validationSchema = Yup.object().shape({
  order: Yup.string().required("Order is required"),
  code: Yup.string().required("Code is required"),
  // start_date: Yup.string().required("Start date is required"),
  // end_date: Yup.string().required("End date is required"),
});

const TMSDiagnosisForm = ({
  onSubmit,
  onCancel,
  episodeOfCareId,
  codeObjects,
  codeRanges,
  orders,
  selectedDiagnosisData,
  setOtherOrder,
  setSameOrder,
}) => {
  const { notificationState, handleErrorNotification, handleClose } = useNotificationHandling();
  const [filteredCodeList, setFilteredCodeList] = useState([]);
  const [codeId, setCodeId] = useState();

  useEffect(() => {
    const filteredCodes = codeObjects.map((code) => ({
      id: code.id,
      label: code.code,
      description: code.long_description,
    }));
    setFilteredCodeList(filteredCodes);
  }, [codeObjects]);

  useEffect(() => {
    setSameOrder();
  }, []);

  const handleOrderChange = (e, setFieldValue) => {
    const order = e.target.value;
    const originalRow = { ...selectedDiagnosisData };

    if (originalRow.dx_order !== order) {
      setOtherOrder();
    } else {
      setSameOrder();
    }
    setFieldValue("order", order);
  };

  const onSave = (values) => {
    const dataToSubmit = {
      episode_of_care_id: episodeOfCareId,
      diagnosis_code_id: codeId,
      dx_order: values.order,
      start_date: values.start_date,
      end_date: values.end_date,
    };

    onSubmit(dataToSubmit);
  };

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <Formik
      initialValues={{
        order: selectedDiagnosisData?.dx_order || '',
        range: selectedDiagnosisData?.codeRange || '',
        code: selectedDiagnosisData?.diagnosis_code_id || '',
        start_date: selectedDiagnosisData?.start_date || null,
        end_date: selectedDiagnosisData?.end_date || null,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSave(values)}
    >
      {({
        errors,
        touched,
        handleChange,
        setFieldValue,
        values,
        setValues,
      }) => (
        <Form>
          <Box
            sx={{
              maxWidth: 600,
              mx: 'auto',
              p: 3,
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <Typography variant='h6' gutterBottom>
              Order
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Select
                    labelId='order-label'
                    id='order'
                    name='order'
                    value={values.order}
                    onChange={(e) => handleOrderChange(e, setFieldValue)}
                    error={touched.order && Boolean(errors.order)}
                  >
                    {orders.map((order, index) => (
                      <MenuItem key={index} value={order}>
                        {`${order}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.order && errors.order && (
                    <Typography color='error'>{errors.order}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <Field
                  name='start_date'
                  as={TextField}
                  label='Start Date'
                  type='date'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={values.start_date}
                  onChange={handleChange}
                  error={touched.start_date && Boolean(errors.start_date)}
                  helperText={touched.start_date && errors.start_date}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name='end_date'
                  as={TextField}
                  label='End Date'
                  type='date'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={values.end_date}
                  onChange={handleChange}
                  error={touched.end_date && Boolean(errors.end_date)}
                  helperText={touched.end_date && errors.end_date}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  ICD-10 Code
                </Typography>
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-demo"
                    slotProps={{
                      paper: {
                        sx: {
                          "& .MuiAutocomplete-listbox": {
                            height: "300px",
                            zIndex: "9999",
                          },
                        },
                      },
                    }}
                    value={filteredCodeList.find(option => option.id === values.code) || null}
                    options={filteredCodeList}
                    renderInput={(params) => (
                      <TextField {...params} label="Select the code." />
                    )}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(event, option) => {
                      if (option) {
                        setCodeId(option.id);
                        setFieldValue("code", option.id);
                      }
                    }}
                    renderOption={(props, option) => {
                      const { key, ...optionProps } = props;
                      return (
                        <Box
                          key={key}
                          sx={{
                            width: "100%",
                            display: "flex",
                            padding: "10px",
                            paddingInline: "20px",
                            gap: "20px",
                            borderBottom: "1px solid #E1E1E1",
                          }}
                          {...optionProps}
                        >
                          <Typography color={"#4A8C7A"}>
                            {option.label}
                          </Typography>
                          <Typography>{option.description}</Typography>
                        </Box>
                      );
                    }}
                  />
                  {touched.code && errors.code && (
                    <Typography color='error'>{errors.code}</Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type='button'
                  variant='outlined'
                  color='secondary'
                  fullWidth
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default TMSDiagnosisForm;
