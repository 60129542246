import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  Grid,
  Typography,
  Box,
} from '@mui/material';
import { getData } from '../../../utils/API';
import ShowAlert from '../../../utils/ShowAlert';
import { useNotificationHandling } from '../../../utils/NotificationHandling';

const validationSchema = Yup.object().shape({
  practice_tms_treatment_plan_id: Yup.string().required(
    'Treatment Plan is required'
  ),
  motor_threshold_percent: Yup.number()
    .min(1)
    .max(200)
    .required('Motor Threshold Percent is required'),
  motor_threshold_calibrated: Yup.number()
    .min(1)
    .max(200)
    .required('Motor Threshold Calibrated is required'),
  session_start: Yup.number()
    .min(1)
    .max(36)
    .required('Session Start is required'),
  session_end: Yup.number().min(1).max(36).required('Session End is required'),
});

const TMSTreatmentPlanForm = ({
  onSubmit,
  onCancel,
  practiceId,
  officeId,
  patientId,
  episodeOfCareId,
  practiceTreatmentPlans,
  treatmentPlanData
}) => {
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();

  console.log(practiceTreatmentPlans, 'practiceTreatmentPlans')

  const handlePlanChange = (e, setFieldValue) => {
    const planId = e.target.value;
    const selectedPlan = practiceTreatmentPlans.find(
      (plan) => plan.id === planId
    );
    setFieldValue('practice_tms_treatment_plan_id', planId);
    if (selectedPlan) {
      setFieldValue(
        'motor_threshold_percent',
        selectedPlan.motor_threshold_percent
      );
      setFieldValue(
        'tms_device_manufacturer',
        selectedPlan.device_manufacturer
      );
      setFieldValue(
        'tms_device_model_number',
        selectedPlan.device_model_number
      );
      setFieldValue('tms_coil_model_number', selectedPlan.coil_model_number);
      setFieldValue('tms_protocol_name', selectedPlan.tms_protocol_name);
    }
  };

  const onSave = (values) => {
    // Create the data object with the required fields
    const dataToSubmit = {
      practice_id: practiceId,
      office_id: officeId,
      patient_id: patientId,
      episode_of_care_id: episodeOfCareId,
      practice_tms_treatment_plan_id: values.practice_tms_treatment_plan_id,
      motor_threshold_percent: values.motor_threshold_percent,
      motor_threshold_calibrated: values.motor_threshold_calibrated,
      session_start: values.session_start,
      session_end: values.session_end,
      status: 'Active',
      deleted: false,
      tms_device_manufacturer: values.tms_device_manufacturer,
      tms_device_model_number: values.tms_device_model_number,
      tms_coil_model_number: values.tms_coil_model_number,
      tms_protocol_name: values.tms_protocol_name,
    };

    onSubmit(dataToSubmit);
  };

  if (notificationState.showNotification) {
    return (
      <ShowAlert
        severity={notificationState.severity}
        title={notificationState.title}
        message={notificationState.message}
        description={notificationState.description}
        onClose={handleClose}
      />
    );
  }

  return (
    <Formik
      initialValues={{
        practice_tms_treatment_plan_id: treatmentPlanData?.practice_tms_treatment_plan_id || '',
        motor_threshold_percent: treatmentPlanData?.motor_threshold_percent || '',
        motor_threshold_calibrated: treatmentPlanData?.motor_threshold_calibrated || '',
        session_start: treatmentPlanData?.session_start || 1,
        session_end: treatmentPlanData?.session_end || 36,
        tms_device_manufacturer: treatmentPlanData?.tms_device_manufacturer || '',
        tms_device_model_number: treatmentPlanData?.tms_device_model_number || '',
        tms_coil_model_number: treatmentPlanData?.tms_coil_model_number || '',
        tms_protocol_name: treatmentPlanData?.tms_protocol_name || '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSave(values);
      }}
    >
      {({
        errors,
        touched,
        handleChange,
        setFieldValue,
        values,
        setValues,
      }) => (
        <Form>
          <Box
            sx={{
              maxWidth: 600,
              mx: 'auto',
              p: 3,
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <Typography variant='h6' gutterBottom>
              Select Treatment Plan
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Select
                    labelId='treatment-plan-label'
                    id='practice_tms_treatment_plan_id'
                    name='practice_tms_treatment_plan_id'
                    value={values.practice_tms_treatment_plan_id}
                    onChange={(e) => handlePlanChange(e, setFieldValue)}
                    error={
                      touched.practice_tms_treatment_plan_id &&
                      Boolean(errors.practice_tms_treatment_plan_id)
                    }
                  >
                    {practiceTreatmentPlans.map((plan) => (
                      <MenuItem key={plan.id} value={plan.id}>
                        {`${plan.device_manufacturer} - ${plan.device_model_number} - ${plan.coil_model_number} - ${plan.tms_protocol_name}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.practice_tms_treatment_plan_id &&
                    errors.practice_tms_treatment_plan_id && (
                      <Typography color='error'>
                        {errors.practice_tms_treatment_plan_id}
                      </Typography>
                    )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name='motor_threshold_percent'
                  as={TextField}
                  label='Motor Threshold Percent'
                  type='number'
                  fullWidth
                  value={values.motor_threshold_percent}
                  onChange={handleChange}
                  error={
                    touched.motor_threshold_percent &&
                    Boolean(errors.motor_threshold_percent)
                  }
                  helperText={
                    touched.motor_threshold_percent &&
                    errors.motor_threshold_percent
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name='motor_threshold_calibrated'
                  as={TextField}
                  label='Motor Threshold Calibrated'
                  type='number'
                  fullWidth
                  value={values.motor_threshold_calibrated}
                  onChange={handleChange}
                  error={
                    touched.motor_threshold_calibrated &&
                    Boolean(errors.motor_threshold_calibrated)
                  }
                  helperText={
                    touched.motor_threshold_calibrated &&
                    errors.motor_threshold_calibrated
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name='session_start'
                  as={TextField}
                  label='Session Start'
                  type='number'
                  fullWidth
                  value={values.session_start}
                  onChange={handleChange}
                  error={touched.session_start && Boolean(errors.session_start)}
                  helperText={touched.session_start && errors.session_start}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name='session_end'
                  as={TextField}
                  label='Session End'
                  type='number'
                  fullWidth
                  value={values.session_end}
                  onChange={handleChange}
                  error={touched.session_end && Boolean(errors.session_end)}
                  helperText={touched.session_end && errors.session_end}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  type='button'
                  variant='outlined'
                  color='secondary'
                  fullWidth
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default TMSTreatmentPlanForm;
