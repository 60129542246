import * as React from 'react';
import '../../../index.css';
import { useEffect, useState, useContext } from 'react';
import DataEntry from '../../../components/datagrid/dataEntry';
import ViewOnly from '../../../components/datagrid/viewOnly';
import UserContext from '../../../contexts/UserContext';
import { getData, postData, deleteData } from '../../../utils/API';
import { validateRequiredAttributes } from '../../../utils/ValidationUtils';
import ShowAlert from '../../../utils/ShowAlert';
import { useNotificationHandling } from '../../../utils/NotificationHandling';
import logger from '../../../utils/logger';

// *************** CUSTOMIZE ************** START
export default function PracticeTMSProtocolsGrid() {
  const { role, practiceId, practice_name } = useContext(UserContext);
  const { notificationState, handleErrorNotification, handleClose } =
    useNotificationHandling();

  const [title, setTitle] = useState([]);
  const [subtitle, setSubtitle] = useState([]);
  const table = 'practice_tms_treatment_plans';
  const sort_1 = 'office_name';
  const sort_2 = 'status';

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    {
      field: 'office_name',
      headerName: 'Office',
      cellClassName: 'name-column--cell',
      flex: 1,
    },
    {
      field: 'tms_protocol_name',
      headerName: 'Protocol',
      cellClassName: 'name-column--cell',
      flex: 1,
    },
    {
      field: 'device_name',
      headerName: 'Device',
      flex: 1,
    },
    {
      field: 'coil_name',
      headerName: 'Coil',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'total_pulses',
      headerName: 'Total Pulses',
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => value.toLocaleString(),
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
    },
  ];
  // *************** CUSTOMIZE ************** END

  const [loading, setLoading] = useState(true);
  const [rows, setRawRows] = useState([]);

  const setRows = (rows) => {
    if (!Array.isArray(rows)) {
      return;
    }
    setRawRows(rows.map((r, i) => ({ ...r, no: i + 1 })));
  };

  useEffect(() => {
    if (!practiceId || practiceId === '') {
      // Exit early if practiceId is empty or falsy
      return;
    }

    setLoading(true);
    getData(table, { practice_id: practiceId, deleted: false })
      .then((data) => {
        setTitle(practice_name);
        setSubtitle('TMS Treatment Options');
        setRows(data);
      })
      .catch((error) => {
        handleErrorNotification(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [practiceId, handleErrorNotification]);

  async function validateRow(row) {
    logger.info(`validateRow, ${row}`);
  }

  async function saveRow(row, oldRows) {
    logger.info(`saveRow, ${row}`);
  }

  async function deleteRow(id, row, oldRows) {
    logger.info(`deleteRow, ${row}`);
  }

  return (
    <div>
      <ViewOnly
        title={title}
        subtitle={subtitle}
        columns={columns}
        rows={rows}
        loading={loading}
      />
    </div>
  );
}
